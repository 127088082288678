import dayjs from 'dayjs';
import { MRT_SortingState } from 'material-react-table';

export interface CreateCredentialModel {
  id?: string;
  issuedOn: string;
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  identifier: string;
  certificateType: string;
  status: string;
  comment?: string;
  expires?: string;
  templateId: string;
  signed?: boolean;
}

export interface Credential extends CreateCredentialModel {
  uid: string;
}

export enum CredentialStatus {
  ACTIVE = 'ACTIVE',
  REVOKED = 'REVOKED',
}

export interface GetCredentialsRequest {
  searchString?: string;
  size?: number;
  page?: number;
  sorting?: MRT_SortingState;
}

export interface GetCredentialsResponse {
  page: number;
  count: number;
  data: Credential[];
  errors: string;
}

export interface CredentialTemplateField {
  fieldLabel: string;
  fieldType: string;
}

export interface CreateCredentialTemplateRequest {
  name: string;
  image: string;
  description: string;
  type: string;
  criteria?: string;
  tags?: string;
  renderParams?: {
    [key: string]: CredentialTemplateField;
  };
  designTemplateUid: string;
}

export interface CreateCredentialRequest {
  templateUid: string;
  issuerUid: string;
  data: {
    credential: {
      issuedOn: string;
      signed: boolean;
      expires?: string;
      comment?: string;
      externalIdentifier?: string;
    };
    recipient: {
      firstName: string;
      middleName?: string;
      lastName: string;
      email: string;
      address?: string;
      birthdate?: string;
      countryId?: string;
      country?: string;
      phone?: string;
      secondaryIdentifier?: string;

      index?: number;
      id?: number;
    };
  };
  renderParams?: Record<string, CredentialTemplateField>;
}

export interface CreateCredentialWithDocumentRequest {
  credential: {
    comment?: string;
    expires?: string;
    issuedOn: string;
    issuerUid?: string;
    qualification?: string;
    templateId: string;
  };
  recipient: {
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    secondaryIdentifier?: string;
    birthdate?: string;
    country?: string;
    countryId?: string;
    phone?: string;
  };
}

export interface NotifyCredential {
  id: string;
}

export interface CredentialRecipientDetails
  extends Record<
    string,
    | string
    | number
    | Date
    | dayjs.Dayjs
    | null
    | undefined
    | Record<string, CredentialTemplateField>
  > {
  id?: number;
  index?: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email?: string;
  secondaryIdentifier?: string;
  country?: string;
  countryId?: string;
  birthdate?: string;
  issuedOn: dayjs.Dayjs | string;
  externalIdentifier?: string;
  expires?: dayjs.Dayjs | string;
  comment?: string;
  renderParams?: Record<string, CredentialTemplateField>;
}

export interface CredentialCreationResult {
  total: number;
  failed: number;
}
