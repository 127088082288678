import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useConfirmPrimaryEmailMobileMutation } from 'src/features/mobile/api/mobileApi';

import './ConfirmPrimaryEmailMobile.scss';

type SeverityType = 'success' | 'info' | 'warning' | 'error';

const ConfirmPrimaryEmailMobile = () => {
  const [severity, setSeverity] = useState<SeverityType>('warning');
  const [message, setMessage] = useState('Invalid link');

  const [searchParams] = useSearchParams();

  const [
    confirmPrimaryEmailMobile,
    { isLoading, isError, isSuccess, isUninitialized },
  ] = useConfirmPrimaryEmailMobileMutation();

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      confirmPrimaryEmailMobile({
        verificationCode: code,
      });
    }
  }, [confirmPrimaryEmailMobile, searchParams]);

  useEffect(() => {
    if (isUninitialized) {
      setSeverity('warning');
      setMessage('Invalid link');
      return;
    }
    if (isLoading) {
      setSeverity('info');
      setMessage('Loading');
      return;
    }
    if (isError) {
      setSeverity('error');
      setMessage(
        'Something went wrong. Please, try again later or contact the support'
      );
      return;
    }
    if (isSuccess) {
      setSeverity('success');
      setMessage('Success! Please, return to the app and login with new email');
      return;
    }
  }, [isError, isLoading, isSuccess, isUninitialized]);

  return (
    <main className="confirm-primary-email-mobile">
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </main>
  );
};

export default ConfirmPrimaryEmailMobile;
