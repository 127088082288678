import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { paths } from 'src/app/routes';
import Card from 'src/shared/components/Card/Card';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import { countries } from 'src/shared/utils';
import { FORM_TYPES } from 'src/shared/enums';
import Input from 'src/shared/components/Input/Input';
import Select from 'src/shared/components/Select/Select';
import TextArea from 'src/shared/components/TextArea/TextArea';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';

import { State } from 'src/features/store/store';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { SIGNATORIES } from 'src/features/signatories/slices/signatoriesSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import {
  useCreateSignatoryMutation,
  useUpdateSignatoryMutation,
} from 'src/features/signatories/api/signatoriesApi';
import {
  CreateSignatoryRequest,
  UpdateSignatoryRequest,
} from 'src/features/signatories/models';

import './SignatoryForm.scss';

const INITIAL_VALUES = {
  id: '',
  name: '',
  email: '',
  url: '',
  image: '',
  country: '',
  countryId: '',
  phone: '',
  address: '',
  userId: '',
  type: 'ORGANIZATION',
  birthdate: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
  image: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required'),
});

const COUNRTY_OPTIONS: { label: string; value: string }[] = countries;

const formTypes = {
  [FORM_TYPES.CREATE]: {
    title: 'New signatory',
    button: 'Create signatory',
  },
  [FORM_TYPES.EDIT]: {
    title: 'Edit signatory',
    button: 'Edit signatory',
  },
  [FORM_TYPES.VIEW]: {
    title: 'Signatory details',
    button: 'Close',
  },
};

type SignatoryFormProps = {
  type: FORM_TYPES;
};

const SignatoryForm = ({ type }: SignatoryFormProps): JSX.Element => {
  const navigate = useNavigate();
  useCheckAuthorization();

  const signatory = useSelector((s: State) => s[SIGNATORIES].signatory);

  const initialFormValues = useMemo<
    CreateSignatoryRequest | UpdateSignatoryRequest
  >(
    () =>
      type !== FORM_TYPES.CREATE && signatory ? signatory : INITIAL_VALUES,
    [type, signatory]
  );
  const [createSignatory, creationResult] = useCreateSignatoryMutation();
  const [updateSignatory, updatingResult] = useUpdateSignatoryMutation();
  const onCreateSignatory = (values: CreateSignatoryRequest) => {
    createSignatory(values);
  };

  const onEditSignatory = (values: UpdateSignatoryRequest) => {
    signatory && updateSignatory({ ...values, id: signatory?.uid });
  };

  useCheckError(creationResult.isError, 'Error creating the signatory');
  useCheckError(updatingResult.isError, 'Error updating the signatory');

  useEffect(() => {
    if (creationResult.isSuccess) {
      navigate(paths.settingsSignatories);
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, creationResult.isSuccess]);

  useEffect(() => {
    if (updatingResult.isSuccess) {
      navigate(paths.settingsSignatories);
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, updatingResult.isSuccess]);

  return (
    <Page>
      <div className="signatory-form">
        <Breadcrumbs>
          <NavLink
            className="signatory-form__bread-crumbs"
            to={paths.settingsSignatories}
          >
            Signatories
          </NavLink>
          <h1>{formTypes[type].title}</h1>
        </Breadcrumbs>
        <Card className="signatory-form__card">
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={
              type === FORM_TYPES.EDIT ? onEditSignatory : onCreateSignatory
            }
            validationSchema={validationSchema}
          >
            <Form className="signatory-form__form">
              <PicturePicker
                title="Logo"
                name="image"
                className="create-template-form__picture"
                disabled={type === FORM_TYPES.VIEW}
              />
              <div>
                <Input
                  name="name"
                  placeholder="Organisation name"
                  label="Name"
                  className="signatory-form__input"
                  disabled={type === FORM_TYPES.VIEW}
                  required
                />
                <div className="signatory-form__inputs-group">
                  <Input
                    name="email"
                    placeholder="Email"
                    label="Email"
                    disabled={type === FORM_TYPES.VIEW}
                    required
                  />
                  <Input
                    name="url"
                    placeholder="Webside address"
                    label="Website URL"
                    disabled={type === FORM_TYPES.VIEW}
                  />
                  <Select
                    name="country"
                    label="Country"
                    options={COUNRTY_OPTIONS}
                    placeholder="Choose country"
                    disabled={type === FORM_TYPES.VIEW}
                    required
                  />
                  <Input
                    name="countryId"
                    placeholder="Ex.: 1234567"
                    label="Tax ID"
                    disabled={type === FORM_TYPES.VIEW}
                  />
                </div>
              </div>
              <div>
                <div className="signatory-form__header-container">
                  <h2>Additional information</h2>
                  <p>Optional</p>
                </div>

                <Input
                  name="phone"
                  placeholder="Ex.: 12345678"
                  label="Phone Number"
                  className="signatory-form__input"
                  disabled={type === FORM_TYPES.VIEW}
                />
                <TextArea
                  name="address"
                  label="Address"
                  placeholder="Recipient address"
                  disabled={type === FORM_TYPES.VIEW}
                />
              </div>
              <div className="signatory-form__buttons">
                <Button
                  color="secondary"
                  className="settings__form-button_secondary"
                  component={NavLink}
                  to={paths.settingsSignatories}
                >
                  {type === FORM_TYPES.VIEW
                    ? formTypes[type].button
                    : 'Discard'}
                </Button>
                {type !== FORM_TYPES.VIEW && (
                  <Button variant="contained" type="submit">
                    {formTypes[type].button}
                  </Button>
                )}
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    </Page>
  );
};

export default SignatoryForm;
