import { Button } from '@mui/material';

import './ChartFilterButtons.scss';

type ChartFilter = {
  name: string;
  onClick: () => void;
  selected?: boolean;
};

type ChartFilterButtonsProps = {
  filters: ChartFilter[];
  type: BUTTONS_TYPE;
  className?: string;
};

export enum BUTTONS_TYPE {
  TEXT = 'text',
  CONTAINED = 'contained',
}

const ChartFilterButtons = ({
  filters,
  type,
  className,
}: ChartFilterButtonsProps) => (
  <div className={`chart-filters ${className ? className : ''}`}>
    {type === BUTTONS_TYPE.CONTAINED &&
      filters.map((filter, key) => (
        <Button
          key={key}
          onClick={filter.onClick}
          variant={filter.selected ? 'contained' : 'text'}
          className={filter.selected ? 'contained' : 'primary-button'}
        >
          {filter.name}
        </Button>
      ))}
    {type === BUTTONS_TYPE.TEXT &&
      filters.map((filter, key) => (
        <Button
          key={key}
          onClick={filter.onClick}
          variant={'text'}
          className={filter.selected ? '' : 'filter-button'}
        >
          {filter.name}
        </Button>
      ))}
  </div>
);

export default ChartFilterButtons;
