import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CredentialCreationResult,
  CredentialRecipientDetails,
} from '../models';

export const CREDENTIALS = 'CREDENTIALS';

interface CredentialState {
  credentialRecipientsDetails: CredentialRecipientDetails[];
  currentRecipient: CredentialRecipientDetails | null;
  credentialsCreatingResult: CredentialCreationResult;
}

const initialState: CredentialState = {
  credentialRecipientsDetails: [],
  currentRecipient: null,
  credentialsCreatingResult: {
    total: 0,
    failed: 0,
  },
};

const credentialsSlice = createSlice({
  name: CREDENTIALS,
  initialState,
  reducers: {
    addCredentialDetails: (
      state: CredentialState,
      action: PayloadAction<CredentialRecipientDetails[]>
    ) => {
      state.credentialRecipientsDetails = [
        ...state.credentialRecipientsDetails,
        ...action.payload,
      ];
    },
    clearCredentialDetails: (state: CredentialState) => {
      state.credentialRecipientsDetails = [];
    },
    getCredentialDetailByIndex: (
      state: CredentialState,
      action: PayloadAction<number>
    ) => {
      state.currentRecipient = {
        ...state.credentialRecipientsDetails[action.payload],
        index: action.payload,
      };
    },
    editCredentialDetailByIndex: (
      state: CredentialState,
      action: PayloadAction<CredentialRecipientDetails & { id: number }>
    ) => {
      state.credentialRecipientsDetails = [
        ...state.credentialRecipientsDetails.slice(0, action.payload.id),
        action.payload,
        ...state.credentialRecipientsDetails.slice(action.payload.id + 1),
      ];
    },
    deleteCredentialDetailByIndex: (
      state: CredentialState,
      action: PayloadAction<number>
    ) => {
      state.credentialRecipientsDetails = [
        ...state.credentialRecipientsDetails.slice(0, action.payload),
        ...state.credentialRecipientsDetails.slice(action.payload + 1),
      ];
    },
    setCurrentRecipient: (
      state: CredentialState,
      action: PayloadAction<CredentialRecipientDetails>
    ) => {
      state.currentRecipient = action.payload;
    },
    setCredentialsCreatingResult: (
      state: CredentialState,
      action: PayloadAction<CredentialCreationResult>
    ) => {
      state.credentialsCreatingResult = action.payload;
    },
  },
});

export const {
  addCredentialDetails,
  clearCredentialDetails,
  getCredentialDetailByIndex,
  editCredentialDetailByIndex,
  deleteCredentialDetailByIndex,
  setCurrentRecipient,
  setCredentialsCreatingResult,
} = credentialsSlice.actions;

export default credentialsSlice.reducer;
