import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CreateCampaignRequest } from '../models';

export const CAMPAIGNS = 'CAMPAIGNS';

type CampaignDetails = CreateCampaignRequest & { uid: string };

interface CampaignState {
  campaignDetails: CampaignDetails;
}

const initialState: CampaignState = {
  campaignDetails: {
    uid: '',
    title: '',
    message: '',
  },
};

const campaignsSlice = createSlice({
  name: CAMPAIGNS,
  initialState,
  reducers: {
    setCampaignDetails: (
      state: CampaignState,
      action: PayloadAction<CampaignDetails>
    ) => {
      state.campaignDetails = action.payload;
    },
    clearCampaignDetails: (state: CampaignState) => {
      state.campaignDetails = {
        uid: '',
        title: '',
        message: '',
      };
    },
  },
});

export const { setCampaignDetails, clearCampaignDetails } =
  campaignsSlice.actions;

export default campaignsSlice.reducer;
