import { Chip } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

export enum PaymentStatusValue {
  PAID = 'paid',
  PENDING = 'pending',
  IN_TRANSIT = 'in_transit',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

type PaymentStatusProps = {
  value: PaymentStatusValue;
};

const STATUSES = {
  [PaymentStatusValue.PAID]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Paid',
    className: 'payment-status_paid',
  },
  [PaymentStatusValue.PENDING]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'payment-status_pending',
  },
  [PaymentStatusValue.IN_TRANSIT]: {
    icon: <Icon icon={IconType.Ship} />,
    label: 'In transit',
    className: 'payment-status_paid',
  },
  [PaymentStatusValue.CANCELED]: {
    icon: <Icon icon={IconType.WarningFilled} />,
    label: 'Canceled',
    className: 'payment-status_paid',
  },
  [PaymentStatusValue.FAILED]: {
    icon: <Icon icon={IconType.CrossCircle} />,
    label: 'Denied',
    className: 'payment-status_denied',
  },
};

const PaymentStatus = ({ value }: PaymentStatusProps) => {
  return STATUSES[value] ? (
    <Chip
      icon={STATUSES[value].icon}
      label={STATUSES[value].label}
      className={STATUSES[value].className}
    />
  ) : null;
};

export default PaymentStatus;
