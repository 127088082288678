import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const TESTIMONIAL = 'TESTIMONIAL';

interface TestimonialState {
  requestId: string | null;
}

const initialState: TestimonialState = {
  requestId: null,
};

const testimonialSlice = createSlice({
  name: TESTIMONIAL,
  initialState,
  reducers: {
    setRequestId: (
      state: TestimonialState,
      action: PayloadAction<string | null>
    ) => {
      state.requestId = action.payload;
    },
  },
});

export const { setRequestId } = testimonialSlice.actions;

export default testimonialSlice.reducer;
