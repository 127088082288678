import { Chip } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { CampaignStatus as CampaignStatusValues } from 'src/features/campaigns/models';

type CampaignStatusProps = {
  value: CampaignStatusValues;
};

const STATUSES = {
  [CampaignStatusValues.DRAFT]: {
    icon: <Icon icon={IconType.Pencil} />,
    label: 'Draft',
    className: 'campaign-status_draft',
  },
  [CampaignStatusValues.SENT]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Sent',
    className: 'campaign-status_sent',
  },
  [CampaignStatusValues.FAILED]: {
    icon: <Icon icon={IconType.CrossCircle} />,
    label: 'Failed',
    className: 'campaign-status_failed',
  },
  [CampaignStatusValues.DELETED]: {
    icon: <Icon icon={IconType.CrossCircle} />,
    label: 'Deleted',
    className: 'campaign-status_deleted',
  },
};

const CampaignStatus = ({ value }: CampaignStatusProps) => {
  return (
    <Chip
      icon={STATUSES[value].icon}
      label={STATUSES[value].label}
      className={STATUSES[value].className}
    />
  );
};

export default CampaignStatus;
