import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import { useLazyGetMyProfileQuery } from './auth/api/authApi';
import { CONTAINER_ID_ACTION } from './notifications/components/NotificationContainer/NotificationContainer';

export const useCheckError = (isError: boolean, message: string) => {
  useEffect(() => {
    if (isError) {
      toast.error(message, { containerId: CONTAINER_ID_ACTION });
    }
  }, [isError, message]);
};

export const useCheckAuthorization = () => {
  const navigate = useNavigate();
  const [getProfile] = useLazyGetMyProfileQuery();

  useEffect(() => {
    getProfile()
      .unwrap()
      .catch(() => {
        localStorage.removeItem('ncap-token');
        navigate(paths.signIn);
        window.location.reload();
      });
  }, [getProfile, navigate]);
};
