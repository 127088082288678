import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';
import { DefaultResponse } from 'src/shared/models';

import {
  ChangeRequestStatus,
  GetTestimonialByIdResponse,
  GetTestimonialRequestsResponse,
  UpdateTestimonialById,
} from '../models';

export const TESTIMONIALS_API = 'TESTIMONIALS_API';

const testimonialsApi = createApi({
  reducerPath: TESTIMONIALS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['TESTIMONIALS'],
  endpoints: (builder) => ({
    getTestimonialRequests: builder.query<
      GetTestimonialRequestsResponse,
      string
    >({
      query: (status: string) => ({
        method: 'GET',
        url: `request?status=${status}`,
      }),
      providesTags: ['TESTIMONIALS'],
    }),
    getTestimonialById: builder.query<GetTestimonialByIdResponse, string>({
      query: (testimonialId) => ({
        method: 'GET',
        url: `testimonial/${testimonialId}`,
      }),
      providesTags: ['TESTIMONIALS'],
    }),
    updateTestimonial: builder.mutation<DefaultResponse, UpdateTestimonialById>(
      {
        query: (request) => ({
          method: 'PUT',
          url: `testimonial/${request.uid}`,
          body: request,
        }),
        invalidatesTags: ['TESTIMONIALS'],
      }
    ),
    changeRequestStatus: builder.mutation<DefaultResponse, ChangeRequestStatus>(
      {
        query: (request) => ({
          method: 'PUT',
          url: `testimonial/${request.uid}/status`,
          body: {
            status: request.status,
          },
        }),
        invalidatesTags: ['TESTIMONIALS'],
      }
    ),
  }),
});

export const {
  useGetTestimonialRequestsQuery,
  useGetTestimonialByIdQuery,
  useLazyGetTestimonialByIdQuery,
  useUpdateTestimonialMutation,
  useChangeRequestStatusMutation,
} = testimonialsApi;

export const testimonialsMiddleware = testimonialsApi.middleware;

export default testimonialsApi.reducer;
