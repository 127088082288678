import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Modal from 'src/shared/components/Modal/Modal';
import { store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';

import { ReactComponent as LogoutIcon } from './images/logout-icon.svg';

const Logout = () => {
  const navigate = useNavigate();

  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const logout = () => {
    localStorage.removeItem('ncap-token');
    navigate(paths.signIn);
    window.location.reload();
  };

  return (
    <Modal
      open={true}
      title="Log out of NCap™?"
      text="Please confirm you want to log out."
      confirmButtonText="Log out"
      onBackClick={closeModal}
      onConfirmClick={logout}
      color="error"
      icon={<LogoutIcon />}
    />
  );
};

export default Logout;
