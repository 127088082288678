import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  ConfirmPrimaryEmailRequest,
  ConfirmSecondaryEmailRequest,
  ConfirmEmailResponse,
} from '../models';

export const MOBILE_API = 'MOBILE_API';

const mobileApi = createApi({
  reducerPath: MOBILE_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
    }),
    {
      maxRetries: 0,
    }
  ),
  endpoints: (builder) => ({
    confirmPrimaryEmailMobile: builder.mutation<
      ConfirmEmailResponse,
      ConfirmPrimaryEmailRequest
    >({
      query: (request) => ({
        method: 'PUT',
        url: 'user-profile/mobile/primary-email/confirm',
        body: request,
      }),
    }),
    confirmSecondaryEmailMobile: builder.mutation<
      ConfirmEmailResponse,
      ConfirmSecondaryEmailRequest
    >({
      query: (request) => ({
        method: 'PUT',
        url: 'user-profile/mobile/secondary-email/confirm',
        body: request,
      }),
    }),
  }),
});

export const {
  useConfirmPrimaryEmailMobileMutation,
  useConfirmSecondaryEmailMobileMutation,
} = mobileApi;

export const mobileMiddleware = mobileApi.middleware;

export default mobileApi.reducer;
