import { CircularProgress } from '@mui/material';

import './Spinner.scss';

interface SpinnerProps {
  className?: string;
}

const Spinner = ({ className }: SpinnerProps) => {
  return (
    <div className={`spinner-container ${className ?? ''}`}>
      <CircularProgress />
    </div>
  );
};

export default Spinner;
