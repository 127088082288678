import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { paths } from 'src/app/routes';
import Card from 'src/shared/components/Card/Card';
import Tabs from 'src/shared/components/Tabs/Tabs';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { UserRoles } from 'src/features/auth/models';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { rolesEquals } from 'src/features/auth/utils';
import { useCheckAuthorization } from 'src/features/hooks';
import { State } from 'src/features/store/store';

import Templates from './components/Templates/Templates';
import Signatories from './components/Signatories/Signatories';
import AdminsSettings from './components/AdminsSettings/AdminsSettings';
import GeneralSettings from './components/GeneralSettings/GeneralSettings';
import TemplateDesigner from './components/TemplateDesigner/TemplateDesigner';

import './Settings.scss';

interface SettingsProps {
  tabNumber: number;
}

const Settings = ({ tabNumber }: SettingsProps): JSX.Element => {
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  useCheckAuthorization();

  const TABS = useMemo(() => {
    return [
      {
        label: 'General settings',
        component: <GeneralSettings />,
        path: paths.settings,
      },
      {
        label: 'Templates',
        component: <Templates />,
        path: paths.settingsTemplates,
        isHidden:
          rolesEquals(userRoles, [UserRoles.PYA]) ||
          rolesEquals(userRoles, [UserRoles.PYA_ADMIN]),
      },
      {
        label: 'Signatories',
        component: <Signatories />,
        path: paths.settingsSignatories,
        isHidden:
          rolesEquals(userRoles, [UserRoles.PYA]) ||
          rolesEquals(userRoles, [UserRoles.PYA_ADMIN]),
      },
      {
        label: 'Admin settings',
        component: <AdminsSettings />,
        path: paths.settingsAdmins,
        isHidden: !userRoles?.includes(UserRoles.NAVOZYME_ADMIN),
      },
      {
        label: 'Template designer',
        component: <TemplateDesigner />,
        path: paths.settingsTemplateDesigner,
        isHidden: !userRoles?.includes(UserRoles.NAVOZYME_ADMIN),
      },
    ];
  }, [userRoles]);

  return (
    <Page>
      <div className="settings">
        <h1>Settings</h1>
        <Card className="settings-card">
          <Tabs value={tabNumber} values={TABS} />
          {TABS[tabNumber].component}
        </Card>
      </div>
    </Page>
  );
};

export default Settings;
