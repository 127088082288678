import { Button } from '@mui/material';

// import Icon from 'src/shared/components/Icon/Icon';
// import { IconType } from 'src/shared/components/Icon/IconType';

import './NotificationPreview.scss';

export type NotificationPreviewProps = {
  name: string;
  description?: string;
  showButton?: boolean;
  onClick?: () => void;
};

const NotificationPreview = ({
  name,
  description,
  showButton = true,
  onClick,
}: NotificationPreviewProps) => {
  return (
    <div className="notification-preview">
      <div className="notification-preview__info">
        <div>
          <h3 className="notification-preview-info__name">{name}</h3>
          {description ? (
            <p className="notification-preview-info__description">
              {description}
            </p>
          ) : null}
        </div>
      </div>

      {showButton ? (
        // <IconButton className="notification-preview__button" onClick={onClick}>
        //   <Icon icon={IconType.Chevron} />
        // </IconButton>
        <Button onClick={onClick}>Mark as read</Button>
      ) : null}
    </div>
  );
};

export default NotificationPreview;
