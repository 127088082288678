import { initializeApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from 'firebase/messaging';
import { firebaseConfig } from './features/notifications/firebase-config';

const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

export const getFirebaseToken = async (setToken: (token: string) => void) => {
  const serviceWorkerRegistration = await navigator.serviceWorker.register(
    `${process.env.PUBLIC_URL}/service-worker.js`
  );
  setTimeout(
    () =>
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          return getToken(messaging, {
            vapidKey: vapidKey,
            serviceWorkerRegistration: serviceWorkerRegistration,
          })
            .then((currentToken) => {
              if (currentToken) {
                setToken(currentToken);
              } else {
                console.log(
                  'No registration token available. Request permission to generate one.'
                );
              }
            })
            .catch((err) => {
              console.log('An error occurred while retrieving token. ', err);
            });
        } else {
          console.log('User Permission Denied.');
        }
      }),
    500
  );
};

export const onMessageListener = (handler: (payload: MessagePayload) => void) =>
  onMessage(messaging, (payload) => {
    handler(payload);
  });
