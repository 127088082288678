import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import Modal from 'src/shared/components/Modal/Modal';
import { IconType } from 'src/shared/components/Icon/IconType';

import { store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { clearCampaignDetails } from 'src/features/campaigns/slices/campaignsSlice';

const CampaignSentSuccess = () => {
  const navigate = useNavigate();

  return (
    <Modal
      open={true}
      title="Campaign Successfully Sent!"
      text="You can check the status of the campaign in the NCap™ Engage Campaign List."
      confirmButtonText="Go to Campaign List"
      hideBackButton
      onConfirmClick={() => {
        store.dispatch(changeModal(null));
        store.dispatch(clearCampaignDetails());
        navigate(paths.campaigns);
      }}
      icon={
        <Icon
          icon={IconType.Verify}
          className="credentials-creation__success"
        />
      }
    />
  );
};

export default CampaignSentSuccess;
