import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import Icon from 'src/shared/components/Icon/Icon';
import Table from 'src/shared/components/Table/Table';
import { IconType } from 'src/shared/components/Icon/IconType';
import { DATE_FORMAT, DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';
import FileUploader from 'src/shared/components/FileUploader/FileUploader';

import { MODALS } from 'src/features/modal/models';
import { State, store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CredentialRecipientDetails } from 'src/features/credentials/models';
import {
  CREDENTIALS,
  deleteCredentialDetailByIndex,
  getCredentialDetailByIndex,
} from 'src/features/credentials/slices/credentialsSlice';

import './AddRecipientDetails.scss';

type AddRecipientDetailsProps = {
  templateWithDesign: boolean;
  onFileChange: (param: boolean) => void;
};

const AddRecipientDetails = ({
  templateWithDesign,
  onFileChange,
}: AddRecipientDetailsProps): JSX.Element => {
  const [includeDocument, setIncludeDocument] = useState<boolean>(false);
  const credentialDetails = useSelector(
    (s: State) => s[CREDENTIALS].credentialRecipientsDetails
  );

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'firstName',
        Cell: ({ row }) =>
          `${row.getValue('firstName')}  ${
            (row.original as CredentialRecipientDetails).lastName
          }`,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 150,
      },
      {
        header: 'Internal Identifier',
        accessorKey: 'secondaryIdentifier',
        size: 150,
      },
      {
        header: 'Issuance Date',
        accessorKey: 'issuedOn',
        size: 110,
        Cell: ({ cell }) => {
          return dayjs(cell.getValue<string>(), DATE_FORMAT).format(
            DATE_WITHOUT_TIMEZONE
          );
        },
      },
      {
        header: 'Expiry Date',
        accessorKey: 'expires',
        size: 110,
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? dayjs(cell.getValue<string>(), DATE_FORMAT).format(
                DATE_WITHOUT_TIMEZONE
              )
            : '',
      },
      {
        header: '',
        id: 'edit',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          return (
            <IconButton
              className="button_row-actions"
              onClick={() => {
                store.dispatch(changeModal(MODALS.EDIT_RECIPIENT_FORM));
                store.dispatch(getCredentialDetailByIndex(row.index));
              }}
            >
              <Icon icon={IconType.Pencil} />
            </IconButton>
          );
        },
      },
      {
        header: '',
        id: 'delete',
        size: 50,
        Cell: ({ row }) => {
          return (
            <IconButton
              className="button_row-actions"
              onClick={() =>
                store.dispatch(deleteCredentialDetailByIndex(row.index))
              }
            >
              <Icon icon={IconType.CrossCircle} />
            </IconButton>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="add-recipient-form__actions">
        <Button
          variant="contained"
          startIcon={<Icon icon={IconType.Plus} />}
          onClick={() => store.dispatch(changeModal(MODALS.ADD_RECIPIENT_FORM))}
          disabled={!templateWithDesign && credentialDetails.length >= 1}
        >
          Add Recipient
        </Button>
        {templateWithDesign ? (
          <Button
            variant="outlined"
            onClick={() =>
              store.dispatch(changeModal(MODALS.IMPORT_SPREADSHEET))
            }
          >
            Import Spreadsheet
          </Button>
        ) : null}
      </div>
      <Table<CredentialRecipientDetails>
        columns={columns}
        data={credentialDetails}
        enableSorting
        enableRowNumbers
      />
      {templateWithDesign ? null : (
        <p>
          Credentials with templates without design cannot be created in batch
        </p>
      )}
      {
        <FormControlLabel
          control={
            <Checkbox
              value={includeDocument}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIncludeDocument(event.target.checked)
              }
              disabled={credentialDetails.length > 1}
            />
          }
          label="Include document"
        />
      }
      {includeDocument && !(credentialDetails.length > 1) ? (
        <FileUploader fieldName="file" onChange={onFileChange} />
      ) : null}
    </>
  );
};

export default AddRecipientDetails;
