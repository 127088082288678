import { text } from '@pdfme/schemas';

const shortTextSchema = {
  pdf: text.pdf,
  ui: text.ui,
  propPanel: {
    ...text.propPanel,
    defaultSchema: {
      ...text.propPanel.defaultSchema,
      type: 'shortText',
    },
  },
};

export default shortTextSchema;
