import * as idb from 'idb';
import { decodeProfileInfo } from '../auth/utils';

export interface NotificationObjects {
  [key: string]: NotificationObject[];
}

export interface NotificationObject {
  id: string;
  title: string;
  body: string;
  read?: boolean;
}

export const NOTIFICATIONS = 'notifications';

const dbName = 'firebase_notifications';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export interface FirebaseNotificationPayload {
  notification: {
    title: string;
    body: string;
  };
  from: string;
  messageId?: string;
  collapseKey?: string;
}

export const dbPromise = idb.openDB(dbName, 2, {
  upgrade(upgradeDb) {
    if (!upgradeDb.objectStoreNames.contains(NOTIFICATIONS)) {
      upgradeDb.createObjectStore(NOTIFICATIONS);
    }
  },
});

export const setIDBValue = async (
  key: IDBKeyRange | IDBValidKey | undefined,
  val: { notifications: NotificationObject[] }
) => {
  return (await dbPromise).put(NOTIFICATIONS, val, key);
};

export const getIDBValue = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbPromise).get(NOTIFICATIONS, key);
};

export const setNotifications = (
  authToken: string | null,
  payload: FirebaseNotificationPayload
) => {
  const userUid = decodeProfileInfo(authToken).userUid ?? '';
  const notificationObject = {
    id: userUid,
    title: payload.notification.title,
    body: payload.notification.body,
  };

  if (userUid) {
    getIDBValue(userUid).then(
      (notifications: { notifications: NotificationObject[] }) => {
        if (notifications) {
          const obj = {
            notifications: [notificationObject, ...notifications.notifications],
          };
          setIDBValue(userUid, obj);
        } else {
          const obj = {
            notifications: [notificationObject],
          };
          setIDBValue(userUid, obj);
        }
      }
    );
  }
};
