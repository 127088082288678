import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { Breadcrumbs, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { paths } from 'src/app/routes';
import Card from 'src/shared/components/Card/Card';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import { FORM_TYPES } from 'src/shared/enums';
import Input from 'src/shared/components/Input/Input';
import PicturePicker from 'src/shared/components/PicturePicker/PicturePicker';

import { State } from 'src/features/store/store';
import { CreateTemplateRequest } from 'src/features/templates/models';
import { TEMPLATES } from 'src/features/templates/slices/templatesSlice';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';
import {
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
} from 'src/features/templates/api/templatesApi';

import './TemplateForm.scss';

const INITIAL_VALUES = {
  name: '',
  description: '',
  image: '',
};

const validationSchema = Yup.object({
  name: Yup.string().required('This field is required'),
  description: Yup.string().required('This field is required'),
  image: Yup.string().required('This field is required'),
});

const formTypes = {
  [FORM_TYPES.CREATE]: {
    title: 'New template',
    button: 'Create template',
  },
  [FORM_TYPES.EDIT]: {
    title: 'Edit template',
    button: 'Edit template',
  },
  [FORM_TYPES.VIEW]: {
    title: 'Template details',
    button: 'Close',
  },
};

type TemplateFormProps = {
  type: FORM_TYPES;
};

const TemplateForm = ({ type }: TemplateFormProps): JSX.Element => {
  const navigate = useNavigate();
  useCheckAuthorization();

  const template = useSelector((s: State) => s[TEMPLATES].template);

  const initialFormValues = useMemo<CreateTemplateRequest>(
    () => (type !== FORM_TYPES.CREATE && template ? template : INITIAL_VALUES),
    [type, template]
  );
  const [createTemplate, creationResult] = useCreateTemplateMutation();
  const [updateTemplate, updatingResult] = useUpdateTemplateMutation();
  const onCreateTemplate = (values: CreateTemplateRequest) => {
    createTemplate(values);
  };

  const onEditTemplate = (values: CreateTemplateRequest) => {
    template && updateTemplate({ ...values, id: template?.uid });
  };

  useCheckError(creationResult.isError, 'Error creating the template');
  useCheckError(updatingResult.isError, 'Error updating the template');

  useEffect(() => {
    if (creationResult.isSuccess) {
      navigate(paths.settingsTemplates);
      toast.success('Created successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, creationResult.isSuccess]);

  useEffect(() => {
    if (updatingResult.isSuccess) {
      navigate(paths.settingsTemplates);
      toast.success('Updated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, updatingResult.isSuccess]);

  return (
    <Page>
      <div className="create-template">
        <Breadcrumbs>
          <NavLink
            className="create-template__bread-crumbs"
            to={paths.settingsTemplates}
          >
            Templates
          </NavLink>
          <h1>{formTypes[type].title}</h1>
        </Breadcrumbs>
        <Card className="create-template__card">
          <Formik
            initialValues={initialFormValues}
            enableReinitialize
            onSubmit={
              type === FORM_TYPES.EDIT ? onEditTemplate : onCreateTemplate
            }
            validationSchema={validationSchema}
          >
            <Form className="create-template-form">
              <PicturePicker
                title="Template picture"
                name="image"
                className="create-template-form__picture"
                disabled={type === FORM_TYPES.VIEW}
              />
              <div>
                <Input
                  name="name"
                  placeholder="Name your template"
                  label="Name"
                  disabled={type === FORM_TYPES.VIEW}
                />
                <Input
                  name="description"
                  placeholder="What is this template about?"
                  label="Description"
                  disabled={type === FORM_TYPES.VIEW}
                />
              </div>
              <div className="create-template-form__buttons">
                <Button
                  color="secondary"
                  className="settings__form-button_secondary"
                  component={NavLink}
                  to={paths.settingsTemplates}
                >
                  {type === FORM_TYPES.VIEW
                    ? formTypes[type].button
                    : 'Discard'}
                </Button>
                {type !== FORM_TYPES.VIEW && (
                  <Button variant="contained" type="submit">
                    {formTypes[type].button}
                  </Button>
                )}
              </div>
            </Form>
          </Formik>
        </Card>
      </div>
    </Page>
  );
};

export default TemplateForm;
