import { ReactElement } from 'react';

import { ReactComponent as TemplatePreviewIcon } from './images/template-preview-icon.svg';

import './TemplatePreview.scss';

export type TemplatePreviewProps = {
  id: string;
  name: string;
  actionButtons: (id: string, name?: string) => ReactElement[];
  subtitle?: string;
  subtitleClassName?: string;
  withIcon?: boolean;
  image?: string;
};

const TemplatePreview = ({
  id,
  name,
  actionButtons,
  subtitle,
  subtitleClassName,
  withIcon = true,
  image,
}: TemplatePreviewProps) => {
  return (
    <div className="template-preview">
      <div className="template-preview__info">
        {withIcon ? (
          image ? (
            <img
              src={image}
              alt="Picture"
              className="template-preview-info__img"
            />
          ) : (
            <TemplatePreviewIcon className="template-preview-info__icon" />
          )
        ) : null}
        <div>
          <h3 className="template-preview-info__name">{name}</h3>
          {subtitle ? (
            <p
              className={`template-preview-info__subtitle ${
                subtitleClassName ?? ''
              }`}
            >
              {subtitle}
            </p>
          ) : null}
        </div>
      </div>
      <div className="template-preview__actions">{actionButtons(id, name)}</div>
    </div>
  );
};

export default TemplatePreview;
