export interface CoCRequest {
  applicationUid: string;
  type: keyof typeof CoCRequestType;
  status: GUESTApplicationStatus;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  daysYachtService: number;
  monthsYachtService: number;
  daysGuest: number;
  monthsGuest: number;
  daysMaritimeService: number;
  monthsMaritimeService: number;
  daysSeaService: number;
  applicationDocuments: ApplicationDocument[];
  certificateUid?: string;
  firstPaymentUid?: string;
  secondPaymentUid?: string;
}

export interface ApplicationDocument {
  uid: string;
  applicactionUid: string;
  documentUid: string;
  documentType: ApplicationDocumentType;
}

export interface ResponseApplicationDocument {
  uid: string;
  applicactionUid: string;
  documentUid: string;
  documentType: ApplicationDocumentType;
}

export interface ApplicationComment {
  uid: string;
  body: string;
  createdAt: string;
}

export type ApplicationRequirementsType = 'ALL_REQUIRED' | number;

export enum CoCRequestType {
  JUNIOR_STEWARD = 'Junior Steward/Stewardess',
  HEAD_HOUSEKEEPING = 'Yacht Head of Housekeeping',
  HEAD_SERVICES = 'Yacht Head of Services',
  CHIEF_STEWARD = 'Chief Steward/Stewardess',
  SPA_MANAGEMENT = 'Yacht Spa Management',
  PURSER = 'Purser',
  SENIOR_STEWARD = 'Yacht Senior Steward/Stewardess',
}

export enum ApplicationDocumentType {
  SST_12M = 'Verified Senior role, + 12 mths Sea Service & 60 guest day',
  SST_24MB = 'Verified 24 mths Sea Service & 120 guest days',
  SST_24MA = 'Verified 12 mths Sea Service & 60 guest days',
  STWC_TC = 'STCW95 training courses x 5',
  STCW_CMT = 'STCW (V/2, A-V/2) Crowd Management Training',
  STCW_MCC = 'STCW (A-VI/4-2) Medical Care Course',
  STCW_MFA = 'STCW (A-VI/4-1) Medical First Aid',
  STCW_AFF = 'STCW (A-VI/3) Advanced Fire Fighting',
  STCW_ASS = 'STCW (A-VI/2-1) PSC&RB or Advanced Sea Survival',
  RYA_PB = 'RYA Powerboat Level 2 or Tender Driver License',
  BTQ = 'Recognised Beauty Therapist Qualifications',
  MH_FIRST_AID = 'Mental Health First Aid Course',
  MH_AWARENESS = 'Mental Health Awareness Course',
  YRC = 'MCA Yacht Rating Certificate',
  SAFETY_MCF = 'International Safety Management Code Familiarization',
  GUEST_U18 = 'GUEST Yacht Purser Program Units 18 - 21',
  GUEST_U30 = 'GUEST Yacht Human Resources & Crew Wellbeing Unit 30',
  GUEST_U31 = 'GUEST Spa Management Unit 31',
  GUEST_U23 = 'GUEST Introduction to Yachting & Yachting Life Unit 23',
  GUEST_U09 = 'GUEST Interior Valet Services Unit 09',
  GUEST_U07 = 'GUEST Interior Advanced Laundry Service Unit 07',
  GUEST_U08 = 'GUEST Interior Advanced Housekeeping Unit 08',
  GUEST_U05 = 'GUEST Interior Advanced Food & Beverage Service Unit 05',
  GUEST_U04 = 'GUEST Interior Administrations Unit 34 (or Unit 04)',
  GUEST_U22 = 'GUEST IAMI Advanced Leadership Unit 22 or equivalent',
  GUEST_U17 = 'GUEST Foundation Leadership Unit 17',
  GUEST_U36 = 'GUEST Destination, Event & Interior Management Unit 36',
  GUEST_U06 = 'GUEST Cigar Service Unit 06',
  GUEST_U03 = 'GUEST Basic Wine Bartending & Mixology Unit 03',
  GUEST_U32 = 'GUEST Basic Housekeeping & Laundry Unit 32',
  GUEST_U02 = 'GUEST Basic Food Service Unit 02',
  GUEST_U12 = 'GUEST Advanced Wine Appreciation Unit 12 & 13 or equivalent',
  GUEST_U21 = 'GUEST Accounting, Bookkeeping & Budgeting Unit 21  or equivalent qualification or experience',
  FOOD_SAFETY = 'Food Safety/Hygiene level 2 in Catering or equivalent',
  MMS = 'Evidence of 3 years Management role in Maritime Service',
  ITRB = 'Completed Interior Training Record Book',
  STCW_DSD = 'STCW (A-V1/6-2) Proficiency in Designated Security Duties',
  CV = 'CV',
  PASSPORT = 'PASSPORT',
  PASSPORT_PHOTO = 'PASSPORT_PHOTO',
  STCW_1 = 'STCW-1',
  STCW_2 = 'STCW-2',
  STCW_3 = 'STCW-3',
  STCW_4 = 'STCW-4',
  STCW_5 = 'STCW-5',
}

export const documentsTypes = {
  [CoCRequestType.JUNIOR_STEWARD]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.FOOD_SAFETY,
    ],
    applicationRequirementsRequired: 'ALL_REQUIRED',
    stwcCoursesRequired: 2,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U23,
      ApplicationDocumentType.GUEST_U32,
      ApplicationDocumentType.GUEST_U02,
      ApplicationDocumentType.GUEST_U03,
    ],
  },
  [CoCRequestType.HEAD_HOUSEKEEPING]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.MH_AWARENESS,
      ApplicationDocumentType.STCW_MFA,
      ApplicationDocumentType.RYA_PB,
      ApplicationDocumentType.ITRB,
      ApplicationDocumentType.YRC,
    ],
    applicationRequirementsRequired: 1,
    stwcCoursesRequired: 5,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U04,
      ApplicationDocumentType.GUEST_U07,
      ApplicationDocumentType.GUEST_U08,
      ApplicationDocumentType.GUEST_U09,
      ApplicationDocumentType.SST_24MA,
    ],
  },
  [CoCRequestType.HEAD_SERVICES]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.MH_AWARENESS,
      ApplicationDocumentType.STCW_MFA,
      ApplicationDocumentType.RYA_PB,
      ApplicationDocumentType.ITRB,
      ApplicationDocumentType.YRC,
    ],
    applicationRequirementsRequired: 1,
    stwcCoursesRequired: 5,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U04,
      ApplicationDocumentType.GUEST_U05,
      ApplicationDocumentType.GUEST_U12,
      ApplicationDocumentType.GUEST_U06,
      ApplicationDocumentType.SST_24MA,
      ApplicationDocumentType.FOOD_SAFETY,
    ],
  },
  [CoCRequestType.CHIEF_STEWARD]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.MH_FIRST_AID,
      ApplicationDocumentType.STCW_DSD,
      ApplicationDocumentType.STCW_CMT,
      ApplicationDocumentType.STCW_MCC,
      ApplicationDocumentType.STCW_AFF,
      ApplicationDocumentType.STCW_ASS,
      ApplicationDocumentType.SAFETY_MCF,
    ],
    applicationRequirementsRequired: 2,
    stwcCoursesRequired: 2,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U36,
      ApplicationDocumentType.GUEST_U30,
      ApplicationDocumentType.GUEST_U17,
      ApplicationDocumentType.GUEST_U21,
      ApplicationDocumentType.FOOD_SAFETY,
      ApplicationDocumentType.SST_24MB,
    ],
  },
  [CoCRequestType.SPA_MANAGEMENT]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.BTQ,
    ],
    applicationRequirementsRequired: 'ALL_REQUIRED',
    stwcCoursesRequired: 2,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U04,
      ApplicationDocumentType.GUEST_U31,
      ApplicationDocumentType.GUEST_U17,
    ],
  },
  [CoCRequestType.PURSER]: {
    applicationRequirements: [
      ApplicationDocumentType.STWC_TC,
      ApplicationDocumentType.SST_12M,
      ApplicationDocumentType.MMS,
    ],
    applicationRequirementsRequired: 'ALL_REQUIRED',
    stwcCoursesRequired: 2,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U18,
      ApplicationDocumentType.GUEST_U22,
    ],
  },
  [CoCRequestType.SENIOR_STEWARD]: {
    applicationRequirements: [
      ApplicationDocumentType.STCW_MFA,
      ApplicationDocumentType.RYA_PB,
      ApplicationDocumentType.ITRB,
      ApplicationDocumentType.YRC,
    ],
    applicationRequirementsRequired: 1,
    stwcCoursesRequired: 0,
    coursesRequired: [
      ApplicationDocumentType.GUEST_U04,
      ApplicationDocumentType.GUEST_U05,
      ApplicationDocumentType.GUEST_U07,
      ApplicationDocumentType.GUEST_U08,
      ApplicationDocumentType.GUEST_U09,
      ApplicationDocumentType.FOOD_SAFETY,
      ApplicationDocumentType.SST_24MA,
    ],
  },
};

export enum GUESTApplicationStatus {
  DOCUMENTS_REVISION = 'DOCUMENTS_REVISION',
  APPLICATION_PAYMENT_SUCCESS = 'APPLICATION_PAYMENT_SUCCESS',
  DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',

  CERTIFICATE_PAYMENT_FAILED = 'CERTIFICATE_PAYMENT_FAILED',
  CERTIFICATE_PAYMENT_SUCCESS = 'CERTIFICATE_PAYMENT_SUCCESS',
  CERTIFICATE_PAYMENT_PROCESSING = 'CERTIFICATE_PAYMENT_PROCESSING',
  ISSUED = 'ISSUED',
  APPROVED = 'APPROVED',

  REJECTED = 'REJECTED',
}

export interface GetGUESTApplicationsResponse {
  data: CoCRequest[];
}

export interface GetGUESTApplicationByIdRequest {
  applicationUid: string;
  authToken?: string;
}

export interface GetGUESTApplicationByIdResponse {
  data: CoCRequest;
}

export interface GetGUESTApplicationDocumentsRequest {
  applicationUid: string;
  authToken?: string;
}

export interface GetGUESTApplicationDocuments {
  data: ResponseApplicationDocument[];
}

export interface UploadGUESTApplicationDocument {
  uid: string;
  applicationUid: string;
  document: FormData;
  authToken: string;
}

export interface GetGUESTApplicationImage {
  applicationUid: string;
  authToken: string;
}

export interface ChangeGUESTApplicationStatus {
  uid: string;
  status: GUESTApplicationStatus;
  authToken?: string;
}

export interface GetGUESTApplicationComments {
  data: ApplicationComment[];
}

export interface AddGUESTApplicationComment {
  uid: string;
  comment: string;
}
