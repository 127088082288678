import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { ReactElement } from 'react';

import Card from 'src/shared/components/Card/Card';

import './Dropdown.scss';

export type DropdownElement<T> = {
  text: string;
  onClick?: (elem: T) => void;
  icon?: ReactElement;
  className?: string;
  disabled?: (elem: T) => boolean;
};

type DropdownProps<T> = {
  elements: DropdownElement<T>[];
  element?: T;
  callbackOnClick?: () => void;
};

const Dropdown = <T,>({
  elements,
  callbackOnClick,
  element,
}: DropdownProps<T>) => {
  return (
    <Card className="dropdown__container">
      <MenuList>
        {elements.map((elem, key) => (
          <MenuItem
            key={key}
            onClick={() => {
              elem.onClick && element && elem.onClick(element);
              callbackOnClick && callbackOnClick();
            }}
            disabled={elem.disabled && element ? elem.disabled(element) : false}
          >
            {elem.icon ? <ListItemIcon>{elem.icon}</ListItemIcon> : null}
            <ListItemText className={elem.className ?? ''}>
              {elem.text}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Card>
  );
};

export default Dropdown;
