import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

export const CONTAINER_ID_ACTION = 'action';
export const CONTAINER_ID_FIREBASE = 'firebase';

const NotificationContainer = () => (
  <>
    <ToastContainer
      enableMultiContainer
      containerId={CONTAINER_ID_ACTION}
      position="bottom-right"
      style={{ zIndex: '100000' }}
    />
    <ToastContainer
      enableMultiContainer
      containerId={CONTAINER_ID_FIREBASE}
      position="top-right"
      style={{ zIndex: '100000' }}
    />
  </>
);

export default NotificationContainer;
