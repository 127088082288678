import { Button } from '@mui/material';
import { useFormikContext } from 'formik';

import DatePicker from 'src/shared/components/DatePicker/DatePicker';
import { Testimonial } from 'src/features/testimonials/models';

import './LeaveDatesInput.scss';

type LeaveDatesProps = {
  name: string;
  label: string;
  disabled?: boolean;
};

const EMPTY_DATES = {
  from: '',
  to: '',
};

const DATE_FORMAT = 'YYYY-MM-DD';

const LeaveDatesInput = ({
  name,
  label,
  disabled = false,
}: LeaveDatesProps) => {
  const { values, setFieldValue } = useFormikContext<Testimonial>();

  const renderLeave = (key: number) => {
    return (
      <div key={key} className="leave-dates">
        <label className="leave-dates__label">{`Leave ${key + 1}`}</label>
        <div className="leave-dates__container">
          <div>
            <DatePicker
              label="Leave from"
              disabled={disabled}
              value={
                values.leaveDates && values.leaveDates.length >= key + 1
                  ? values.leaveDates[key].from
                  : EMPTY_DATES.from
              }
              onChange={(value) => {
                const dates = values.leaveDates;
                dates[key] = {
                  from: value?.format(DATE_FORMAT) ?? '',
                  to: dates[key].to,
                };
                setFieldValue(name, dates);
              }}
              minDate={values.dateFrom}
              maxDate={values.dateTo}
            />
            <DatePicker
              label="Leave to"
              disabled={disabled}
              value={
                values.leaveDates && values.leaveDates.length >= key + 1
                  ? values.leaveDates[key].to
                  : EMPTY_DATES.to
              }
              onChange={(value) => {
                const dates = values.leaveDates;
                dates[key] = {
                  from: dates[key].from,
                  to: value?.format(DATE_FORMAT) ?? '',
                };
                setFieldValue(name, dates);
              }}
              minDate={values.leaveDates[key].from}
              maxDate={values.dateTo}
            />
          </div>
          {key > 0 ? (
            <Button
              onClick={() =>
                setFieldValue(
                  name,
                  values.leaveDates.filter((date, index) => key !== index)
                )
              }
            >
              Delete
            </Button>
          ) : null}
        </div>
      </div>
    );
  };

  const renderDates = () => {
    if (
      !Array.isArray(values.leaveDates) ||
      typeof values.leaveDates[0] === 'string' ||
      values.leaveDates.length === 0
    ) {
      setFieldValue(name, [EMPTY_DATES]);
    }
    return values.leaveDates?.map((dates, key) => renderLeave(key));
  };

  return (
    <>
      <label className="input__label leave-dates-label" htmlFor={name}>
        {label}
      </label>
      <div>{renderDates()}</div>
      {disabled ? (
        <></>
      ) : (
        <Button
          variant="outlined"
          onClick={() =>
            setFieldValue(name, [...values.leaveDates, EMPTY_DATES])
          }
          disabled={disabled}
        >
          Add another leave
        </Button>
      )}
    </>
  );
};

export default LeaveDatesInput;
