import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { useGetTestimonialsAnalyticsQuery } from 'src/features/analytics/api/analyticsApi';
import { useCheckError } from 'src/features/hooks';

import ChartFilterButtons, {
  BUTTONS_TYPE,
} from '../ChartFilterButtons/ChartFilterButtons';

const TODAY_FORMAT = 'D MMMM';

enum TABS {
  TODAY = 'Today',
  WEEK = 'Week',
  MONTH = 'Month',
}

const ApprovedTestimonialsChart = () => {
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.TODAY);

  const { data: analytics, isError } = useGetTestimonialsAnalyticsQuery();
  useCheckError(isError, 'Error loading downloads');

  const FILTERS = [
    {
      name: TABS.TODAY,
      onClick: () => setSelectedTab(TABS.TODAY),
      selected: selectedTab === TABS.TODAY,
    },
    {
      name: TABS.WEEK,
      onClick: () => setSelectedTab(TABS.WEEK),
      selected: selectedTab === TABS.WEEK,
    },
    {
      name: TABS.MONTH,
      onClick: () => setSelectedTab(TABS.MONTH),
      selected: selectedTab === TABS.MONTH,
    },
  ];

  const data = {
    [TABS.TODAY]: Array.from(analytics?.data.day ?? [])
      .reverse()
      .map((elem) => {
        return { ...elem, name: dayjs(elem.name).format(TODAY_FORMAT) };
      }),
    [TABS.WEEK]: Array.from(analytics?.data.week ?? []).reverse(),
    [TABS.MONTH]: Array.from(analytics?.data.month ?? []).reverse(),
  };

  const count = {
    [TABS.TODAY]: analytics?.data.day?.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.value),
      0
    ),
    [TABS.WEEK]: analytics?.data.week?.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.value),
      0
    ),
    [TABS.MONTH]: analytics?.data.month?.reduce(
      (accumulator, currentValue) => accumulator + Number(currentValue.value),
      0
    ),
  };

  return (
    <div className="chart approved-testimonials-chart">
      <div className="count-box">
        <div className="count-box__number">{count[selectedTab] ?? 0}</div>
        <div className="count-box__label">Testimonials</div>
      </div>
      <div className="chart__body">
        <ChartFilterButtons filters={FILTERS} type={BUTTONS_TYPE.TEXT} />
        <Divider />
        <ResponsiveContainer width="100%" height="90%">
          <Chart
            data={data[selectedTab]}
            margin={{
              top: 20,
              right: 50,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" style={{ fontSize: '14px' }} />
            <YAxis allowDecimals={false} style={{ fontSize: '14px' }} />
            <Tooltip />
            <Bar
              dataKey="value"
              stackId="a"
              fill="var(--ncap-600)"
              radius={[4, 4, 0, 0]}
            />
          </Chart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ApprovedTestimonialsChart;
