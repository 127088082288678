import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { MRT_ColumnDef, MRT_PaginationState } from 'material-react-table';
import { IconButton, Popover, Button } from '@mui/material';

import { paths } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import Card from 'src/shared/components/Card/Card';
import Icon from 'src/shared/components/Icon/Icon';
import Table from 'src/shared/components/Table/Table';
import { DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';
import { IconType } from 'src/shared/components/Icon/IconType';
import Dropdown, {
  DropdownElement,
} from 'src/shared/components/Dropdown/Dropdown';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';

import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import {
  Campaign,
  CampaignStatus as CampaignStatusValues,
} from 'src/features/campaigns/models';
import { useGetCampaignsQuery } from 'src/features/campaigns/api/campaignsApi';
import CampaignStatus from 'src/features/campaigns/components/CampaignStatus/CampaignStatus';

import './Campaigns.scss';

const SIZE = 10;

const Campaigns = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>('');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: SIZE,
  });
  useCheckAuthorization();

  const {
    data: campaignsData,
    isLoading,
    isError,
    isFetching,
  } = useGetCampaignsQuery({
    page: pagination.pageIndex,
    size: pagination.pageSize,
  });
  useCheckError(isError, 'Error loading Campaigns');

  const filterCampaigns = useCallback(
    () =>
      campaignsData
        ? campaignsData.data.filter((campaign) =>
            campaign.title.toLowerCase().includes(search.toLowerCase())
          )
        : [],
    [search, campaignsData]
  );

  useEffect(() => {
    setCampaigns(filterCampaigns());
  }, [filterCampaigns, search]);

  const actions = useCallback(
    (campaign: Campaign) => {
      const elements: DropdownElement<Campaign>[] = [
        {
          text: 'See details',
          onClick: () => {
            navigate(paths.campaignById(campaign.uid));
          },
        },
        // {
        //   text: 'Duplicate',
        // },
        // {
        //   text: 'Delete Campaign',
        //   className: 'campaigns__action-button_delete',
        // },
      ];

      if (campaign.status === CampaignStatusValues.FAILED) {
        elements.unshift({
          text: 'Resend',
        });
      }

      return elements;
    },
    [navigate]
  );

  const columns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Campaign Title',
        accessorKey: 'title',
      },
      {
        header: 'Status',
        accessorKey: 'status',
        minSize: 120,
        size: 120,
        Cell: ({ cell }) => (
          <CampaignStatus value={cell.getValue<CampaignStatusValues>()} />
        ),
      },
      {
        header: 'Created',
        accessorKey: 'createdAt',
        size: 80,
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
      },
      {
        header: 'Published',
        accessorKey: 'publishedAt',
        size: 80,
        Cell: ({ cell }) =>
          cell.getValue()
            ? dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE)
            : '',
      },
      // {
      //   header: 'Target Users',
      //   accessorKey: 'targetUsers',
      //   size: 100,
      // },
      // {
      //   header: 'Open Rate',
      //   accessorKey: 'rate',
      //   size: 100,
      // },
      {
        header: '',
        id: 'actions',
        size: 50,
        maxSize: 50,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown<Campaign>
                  elements={actions(row.original as Campaign)}
                  element={row.original as Campaign}
                  callbackOnClick={handleClose}
                />
              </Popover>
            </>
          );
        },
      },
    ],
    [actions]
  );

  return (
    <Page>
      <div className="campaigns">
        <div className="campaigns__header">
          <h1>NCap™ Engage</h1>

          <Button
            variant="contained"
            startIcon={<Icon icon={IconType.Plus} />}
            component={NavLink}
            to={paths.createCampaign}
          >
            Create new campaign
          </Button>
        </div>
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="campaigns__table-container">
          <Table<Campaign>
            columns={columns}
            data={campaigns}
            enableSorting
            manualPagination
            onPaginationChange={setPagination}
            rowCount={campaignsData?.count ?? 0}
            state={{
              isLoading,
              pagination,
              showProgressBars: isFetching,
            }}
            initialState={{
              sorting: [
                {
                  id: 'createdAt',
                  desc: true,
                },
              ],
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default Campaigns;
