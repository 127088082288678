export enum CoCStatus {
  ACTIVE = 'Valid',
  NOT_REGISTERED = 'Not registered',
}

export enum ResponsiblePersonPosition {
  MASTER = 'Master',
  CAPTAIN = 'Captain',
  OWNERSHIP_COMPANY = 'RP from Management or Ownership Company',
  OWNER = 'Owner',
}

export enum ResponsiblePersonCapacity {
  MASTER = 'Master',
  OWNERSHIP_COMPANY = 'Responsible Person from Management or Ownership Company',
  OWNER = 'Owner',
}

export interface ResponsiblePerson {
  uid: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  dateOfBirth?: string;
  yachtName: string;
  cocStatus: CoCStatus;
  position: ResponsiblePersonPosition;
  pyaMembershipNo: string;
  approvedSSTs: number;
  capacity?: string;
  capacityOther?: string;
  phoneNumber?: string;
  cocNo?: string;
  cocAuthority?: string;
  orgAndAddress?: string;
}

export interface CreateResponsiblePersonRequest {
  uid: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  yachtName: string;
  cocStatus: string;
  position: string;
}

export interface GetResponsiblePersonsResponse {
  data: ResponsiblePerson[];
}

export interface GetResponsiblePersonByIdResponse {
  data: ResponsiblePerson;
}
