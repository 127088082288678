import { ReactNode } from 'react';

import './TextError.scss';

type TextErrorProps = {
  children?: ReactNode;
  className?: string;
};

const TextError = ({ children, className }: TextErrorProps) => {
  return <div className={`error ${className ?? ''}`}>{children}</div>;
};

export default TextError;
