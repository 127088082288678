import { Button } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';
import { openDocumentWithAuthorization } from 'src/shared/utils';

import './DocumentPreview.scss';

interface DocumentPreviewProps {
  name: string;
  applicationUid: string;
  documentUid?: string;
  image?: boolean;
}

const DocumentPreview = ({
  name,
  applicationUid,
  documentUid,
  image = false,
}: DocumentPreviewProps) => {
  const onPreviewClick = () =>
    openDocumentWithAuthorization(
      image
        ? `${process.env.REACT_APP_API_URL}/guest-application/${applicationUid}/image?preview=true`
        : `${process.env.REACT_APP_API_URL}/guest-application/${applicationUid}/document/${documentUid}?preview=true`
    );

  return (
    <div className="coc-request-document-preview">
      <div className="coc-request-document-preview__name-container">
        <Icon icon={IconType.Check} />
        <p className="coc-request-document-preview__name">{name}</p>
      </div>
      <Button
        endIcon={<Icon icon={IconType.GoToLink} />}
        color="secondary"
        className="coc-request-document-preview__link"
        onClick={onPreviewClick}
      >
        Preview
      </Button>
    </div>
  );
};

export default DocumentPreview;
