import { useMemo, useState } from 'react';

import Card from 'src/shared/components/Card/Card';
import Page from 'src/layouts/BaseLayout/components/Page/Page';
import Tabs from 'src/shared/components/Tabs/Tabs';

import {
  getIDBValue,
  setIDBValue,
  NotificationObject,
} from 'src/features/notifications/firebase-config';
import NotificationPreview from 'src/features/notifications/components/NotificationPreview/NotificationPreview';
import { decodeProfileInfo } from 'src/features/auth/utils';
import { useCheckAuthorization } from 'src/features/hooks';

import './Notifications.scss';

const TABS = [
  {
    label: 'New',
  },
  {
    label: 'All',
  },
];

const Notifications = (): JSX.Element => {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [notifications, setNotifications] = useState<NotificationObject[]>([]);

  const authToken = localStorage.getItem('ncap-token') ?? '';
  const userUid = decodeProfileInfo(authToken).userUid;

  useCheckAuthorization();

  const getNotifications = () => {
    if (userUid) {
      getIDBValue(userUid).then(
        (notifications: { notifications: NotificationObject[] }) =>
          notifications
            ? setNotifications(notifications.notifications)
            : setNotifications([])
      );
    }
  };

  const currentNotifications = useMemo(() => {
    if (currentTab === 0) {
      return notifications.filter((notification) => !notification.read);
    } else {
      return notifications;
    }
  }, [currentTab, notifications]);

  getNotifications();

  const onNotificationClick = (index: number) => {
    if (userUid) {
      const readNotifications = notifications.filter(
        (notification) => notification.read
      );
      const allNotifications = currentNotifications.map((notification, key) => {
        if (index === key) {
          return { ...notification, read: true };
        }
        return notification;
      });
      setIDBValue(userUid, {
        notifications: [...allNotifications, ...readNotifications],
      });
    }
  };

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Page>
      <div className="notifications">
        <h1>Notifications</h1>
        <Card className="notifications-card">
          <Tabs value={currentTab} values={TABS} onChange={handleTabClick} />
          {currentNotifications.length
            ? currentNotifications.map((notification, index) => (
                <NotificationPreview
                  name={notification.title}
                  description={notification.body}
                  key={index}
                  onClick={() => onNotificationClick(index)}
                  showButton={!currentTab}
                />
              ))
            : 'There are no new notifications'}
        </Card>
      </div>
    </Page>
  );
};

export default Notifications;
