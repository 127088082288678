import { countryNames, countryShortCodes } from 'country-region-data';
import { encode as base64_encode } from 'base-64';

export const DATE_FORMAT = 'DD-MM-YYYYTHH:mm:ssZ';
export const REQUEST_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_WITHOUT_TIMEZONE = 'DD-MMM-YYYY';

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.{8,})(?=.*[a-z])/;

export const fetchMock = (url: string, externalUrl = false) =>
  fetch(externalUrl ? url : `/mockapi/${url}`).then((response) =>
    response.json()
  );

export const getKey = (obj: object, value: string) => {
  const keyIndex = Object.values(obj).indexOf(value);

  return Object.keys(obj)[keyIndex];
};

export const getBasicAuthKey = (login: string, password: string) =>
  base64_encode(`${login}:${password}`);

export const countries = countryNames.map((country, index) => {
  const countryName = country as unknown as string;
  const countryShortCode = countryShortCodes[index] as unknown as string;
  return { label: countryName, value: countryShortCode };
});

export const makeSelectorOptions = (object: object) =>
  Object.values(object).map((value) => {
    return {
      value: value,
      label: value,
    };
  });

export const fileToBinaryString = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        const binaryString = event.target.result as string;
        resolve(binaryString);
      } else {
        reject(new Error('Failed to read file as binary string.'));
      }
    };

    reader.onerror = (event) => {
      reject(new Error(`Error reading file: ${event.target?.error?.message}`));
    };

    reader.readAsBinaryString(file);
  });
};

export const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target?.result) {
        const arrayBuffer = event.target.result as ArrayBuffer;
        resolve(arrayBuffer);
      } else {
        reject(new Error('Failed to read file as ArrayBuffer.'));
      }
    };

    reader.onerror = (event) => {
      reject(new Error(`Error reading file: ${event.target?.error?.message}`));
    };

    reader.readAsArrayBuffer(file);
  });
};

export const arrayBufferToBinaryString = (arrayBuffer: ArrayBuffer): string => {
  const uint8Array = new Uint8Array(arrayBuffer);
  return String.fromCharCode.apply(null, Array.from<number>(uint8Array));
};

export const downloadDocument = (id: string | string[]) => {
  if (Array.isArray(id)) {
    const url = `${process.env.REACT_APP_TEMPLATES_URL}/credential/documents/download?uids=${id}`;
    const token = localStorage.getItem('ncap-token');
    fetch(url, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => {
        console.log(response);
        return response.blob();
      })
      .then((blob) => {
        const _url = window.URL.createObjectURL(blob);
        window.open(_url, '_blank');
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    const path = `${process.env.REACT_APP_TEMPLATES_URL}/credential/documents/download/${id}`;

    window.open(path, '_blank');
  }
};

export const openDocument = (id: string) => {
  const path = `${process.env.REACT_APP_TEMPLATES_URL}/credential/documents/download/${id}?preview=true`;
  window.open(path, '_blank');
};

export const openDocumentWithAuthorization = (fetchPath: string) =>
  fetch(fetchPath, {
    headers: new Headers({
      Authorization: 'Bearer ' + localStorage.getItem('ncap-token') ?? '',
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const path = window.URL.createObjectURL(blob);
      window.open(path, '_blank');
    })
    .catch((err) => {
      console.log(err);
    });
