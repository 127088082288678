import { useState } from 'react';
import { Field, ErrorMessage, FieldAttributes } from 'formik';

import TextError from '../TextError/TextError';
import { ReactComponent as EyeIcon } from './images/eye-icon.svg';

import './Input.scss';

type InputProps = FieldAttributes<unknown> & {
  label: string;
  name: string;
  info?: string;
  className?: string;
  required?: boolean;
};

const Input = ({
  label,
  name,
  info,
  className,
  required,
  ...rest
}: InputProps) => {
  const [hidePassword, setHidePassword] = useState<boolean>(true);

  let fieldType = rest.type;

  if (rest.type === 'password') {
    fieldType = hidePassword ? 'password' : 'text';
  }

  return (
    <div className="input-container">
      <div className={`form-control input ${className ?? ''}`}>
        <label className="input__label" htmlFor={name}>
          {label}
          {required ? <span className="input__label_required">*</span> : null}
        </label>
        <Field
          className={`form-input form-input_${rest.type}`}
          id={name}
          name={name}
          {...rest}
          type={fieldType}
        />
        {rest.type === 'password' && (
          <EyeIcon
            className="form-input__icon"
            onClick={(e) => {
              e.preventDefault();
              setHidePassword((value) => !value);
            }}
          />
        )}
      </div>
      {info ? <p className="input__info">{info ?? ''}</p> : null}
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Input;
