import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { Button, Divider } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { MRT_ColumnDef } from 'material-react-table';
import { NavLink, useParams } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import Table from 'src/shared/components/Table/Table';
import Input from 'src/shared/components/Input/Input';
import { REQUEST_DATE_FORMAT, DATE_WITHOUT_TIMEZONE } from 'src/shared/utils';
import { IconType } from 'src/shared/components/Icon/IconType';

import { store } from 'src/features/store/store';
import { useCheckError } from 'src/features/hooks';
import { MODALS } from 'src/features/modal/models';
import { UserRoles } from 'src/features/auth/models';
import { Company, CompanyUser } from 'src/features/companies/models';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  useGetCompaniesQuery,
  useLazyGetCompanyUsersQuery,
} from 'src/features/companies/api/companiesApi';

import './AdminsSettings.scss';

const AdminsSettings = () => {
  const { id } = useParams();
  const [currentCompany, setCurrentCompany] = useState<Company>();

  const { data: companies, isError } = useGetCompaniesQuery();
  useCheckError(isError, 'Error loading Companies');

  const [getCompanyUsers, companyUsers] = useLazyGetCompanyUsersQuery();

  useEffect(() => {
    id && getCompanyUsers(id);
  }, [getCompanyUsers, id]);

  const companyColumns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Name',
        accessorKey: 'companyName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 100,
      },
      {
        header: 'VAT',
        accessorKey: 'vatNumber',
        size: 100,
      },
      {
        header: 'City (Country Code)',
        accessorKey: 'city',
        size: 100,
      },
      {
        header: 'Creation Date',
        accessorKey: 'createdAt',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
      },
      {
        header: '',
        id: 'form',
        size: 50,
        Cell: ({ row }) => {
          const uid = (row.original as Company).uid;
          return (
            <NavLink
              to={paths.companyById(uid)}
              onClick={() => setCurrentCompany(row.original as Company)}
            >
              <Icon icon={IconType.ChevronSquare} />
            </NavLink>
          );
        },
      },
    ],
    []
  );

  const usersColumns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'First name',
        accessorKey: 'firstName',
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 100,
      },
      {
        header: 'Joined at',
        accessorKey: 'createdAt',
        size: 100,
        Cell: ({ cell }) =>
          cell.getValue<string>()
            ? dayjs(cell.getValue<string>(), REQUEST_DATE_FORMAT).format(
                DATE_WITHOUT_TIMEZONE
              )
            : '',
      },
      {
        header: 'Permissions',
        accessorKey: 'roles',
        Cell: ({ cell }) => {
          return (
            <div className="settings-users__permissions">
              {cell.getValue<UserRoles[]>().map((role, key) => (
                <span className="settings-users-permissions__role" key={key}>
                  {role}
                </span>
              ))}
            </div>
          );
        },
      },
    ],
    []
  );

  const companiesList = (
    <>
      <div className="settings-admins__header">
        <h2>Companies</h2>
        <Button
          variant="contained"
          startIcon={<Icon icon={IconType.Plus} />}
          onClick={() => store.dispatch(changeModal(MODALS.ADD_COMPANY))}
        >
          Create company
        </Button>
      </div>
      <Table<Company>
        columns={companyColumns}
        data={companies ? companies.data.companies : []}
        enableSorting
      />
    </>
  );

  const companyInfo = (
    <>
      <h2 className="settings-company__header">Company</h2>
      <div className="settings-company__company-info">
        <Formik
          initialValues={currentCompany ?? {}}
          onSubmit={() => console.log('submit')}
        >
          <Form>
            <Input name="companyName" label="Name" disabled />
            <Input name="email" label="Email" disabled />
            <Input name="vatNumber" label="VAT" disabled />
            <Input name="city" label="City (Country Code)" disabled />
            <Input name="createdAt" label="Created Date" disabled />
          </Form>
        </Formik>
      </div>
      <Divider className="settings-company__divider" />
      <div className="settings-users__header">
        <h2>Users</h2>
        <Button
          variant="contained"
          startIcon={<Icon icon={IconType.Plus} />}
          onClick={() => store.dispatch(changeModal(MODALS.ADD_COMPANY_USER))}
        >
          Add user
        </Button>
      </div>
      <Table<CompanyUser>
        columns={usersColumns}
        data={companyUsers.data?.data ?? []}
        enableSorting
      />
    </>
  );

  return (
    <div className="settings__admins">{id ? companyInfo : companiesList}</div>
  );
};

export default AdminsSettings;
