import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  GetPayoutsResponse,
  GetTransactionsRequest,
  GetTransactionsResponse,
} from '../models';

export const PAYMENT_API = 'PAYMENT_API';

const paymentApi = createApi({
  reducerPath: PAYMENT_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    { maxRetries: 0 }
  ),
  endpoints: (builder) => ({
    getTransactions: builder.query<
      GetTransactionsResponse,
      GetTransactionsRequest
    >({
      query: (request) => ({
        method: 'GET',
        url: 'payments/transactions',
        params: request,
      }),
    }),
    getPayouts: builder.query<GetPayoutsResponse, GetTransactionsRequest>({
      query: (request) => ({
        method: 'GET',
        url: 'payments/payouts',
        params: request,
      }),
    }),
  }),
});

export const { useGetTransactionsQuery, useGetPayoutsQuery } = paymentApi;

export const paymentMiddleware = paymentApi.middleware;

export default paymentApi.reducer;
