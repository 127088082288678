import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { Button } from '@mui/material';
import { QueryStatus } from '@reduxjs/toolkit/dist/query';

import { paths } from 'src/app/routes';
import Input from 'src/shared/components/Input/Input';
import Spinner from 'src/shared/components/Spinner/Spinner';
import TextError from 'src/shared/components/TextError/TextError';

import { setIDBAuthValue } from 'src/features/auth/utils';
import { SignInRequest } from 'src/features/auth/models';
import {
  useLazyGetMyProfileByTokenQuery,
  useSignInMutation,
} from 'src/features/auth/api/authApi';

import './SignIn.scss';

const initialSignInValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('This field is required'),
  password: Yup.string().required('This field is required'),
});

const SignIn = (): JSX.Element => {
  const [error, setError] = useState<boolean>(false);
  const [signIn, signInResult] = useSignInMutation();
  const [getProfile] = useLazyGetMyProfileByTokenQuery();
  const navigate = useNavigate();
  const onSubmit = (values: SignInRequest) => signIn(values);

  useEffect(() => {
    const token = signInResult.data?.data.token;
    if (signInResult.status === QueryStatus.fulfilled && token) {
      getProfile(token).then(() => {
        localStorage.setItem('ncap-token', token);
        setIDBAuthValue('ncap-token', token);
        setTimeout(() => navigate(paths.dashboard), 100);
      });
    } else if (signInResult.status === QueryStatus.rejected) {
      setError(true);
    }
  }, [getProfile, navigate, signInResult]);

  return (
    <>
      <h1 className="auth-header">Welcome to NCap™</h1>
      <h2 className="auth-subheader">Please log in to your account</h2>
      <Formik
        initialValues={initialSignInValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        <Form className="sign-in-form">
          <Input
            name="email"
            placeholder="email@gmail.com"
            label="Email"
            type="email"
          />
          <p className="sign-in-form__input-description">
            As stated in your identification
          </p>
          <Input
            name="password"
            placeholder="********"
            label="Password"
            type="password"
          />
          <Link
            to={paths.forgotPassword}
            className="sign-in-form__forgot-password"
          >
            Forgot password?
          </Link>
          {signInResult.isLoading ? (
            <Spinner className="sign-in__spinner-container" />
          ) : (
            <Button type="submit" variant="contained">
              Log in
            </Button>
          )}
          {error ? <TextError>Invalid email or password</TextError> : null}
        </Form>
      </Formik>
    </>
  );
};

export default SignIn;
