import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';

import {
  CreateSignatoryRequest,
  GetSignatoriesResponse,
  UpdateSignatoryRequest,
} from '../models';

export const SIGNATORIES_API = 'SIGNATORIES_API';

const signatoriesApi = createApi({
  reducerPath: SIGNATORIES_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_TEMPLATES_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['SIGNATORIES'],
  endpoints: (builder) => ({
    getSignatories: builder.query<GetSignatoriesResponse, void>({
      query: () => {
        return {
          method: 'GET',
          url: 'credential/issuers',
        };
      },
      providesTags: ['SIGNATORIES'],
    }),
    createSignatory: builder.mutation<void, CreateSignatoryRequest>({
      query: (request) => {
        return {
          method: 'POST',
          url: 'credential/issuers',
          body: request,
        };
      },
      invalidatesTags: ['SIGNATORIES'],
    }),
    updateSignatory: builder.mutation<void, UpdateSignatoryRequest>({
      query: (request) => {
        return {
          method: 'PUT',
          url: 'credential/issuers',
          body: request,
        };
      },
      invalidatesTags: ['SIGNATORIES'],
    }),
  }),
});

export const {
  useGetSignatoriesQuery,
  useCreateSignatoryMutation,
  useUpdateSignatoryMutation,
} = signatoriesApi;

export const signatoriesMiddleware = signatoriesApi.middleware;

export default signatoriesApi.reducer;
