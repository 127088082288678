import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Signatory } from '../models';

export const SIGNATORIES = 'SIGNATORIES';

interface SignatoriesState {
  signatory: Signatory | null;
}

const initialState: SignatoriesState = {
  signatory: {
    uid: '',
    name: '',
    image: '',
    email: '',
    url: '',
    country: '',
    countryId: '',
    phone: '',
    address: '',
    userId: '',
  },
};

const signatoriesSlice = createSlice({
  name: SIGNATORIES,
  initialState,
  reducers: {
    setSignatory: (
      state: SignatoriesState,
      action: PayloadAction<Signatory | null>
    ) => {
      state.signatory = action.payload;
    },
  },
});

export const { setSignatory } = signatoriesSlice.actions;

export default signatoriesSlice.reducer;
