import * as idb from 'idb';
import jwt_decode from 'jwt-decode';

import { ProfileInfo, UserRoles } from './models';

export const TOKEN = 'ncap-token';

export const rolesEquals = (
  userRoles: UserRoles[] | null | undefined,
  roles: UserRoles[]
) => {
  if (userRoles) {
    return (
      userRoles.length === roles.length &&
      userRoles.every((val) => roles.includes(val)) &&
      roles.every((val) => userRoles.includes(val))
    );
  }
  return false;
};

export const decodeProfileInfo = (authToken: string | null) => {
  const decodedToken = authToken ? jwt_decode(authToken) : '';
  return decodedToken as ProfileInfo;
};

const dbAuthName = 'ncap_data';

export const dbAuthPromise = idb.openDB(dbAuthName, 2, {
  upgrade(upgradeDb) {
    if (!upgradeDb.objectStoreNames.contains(TOKEN)) {
      upgradeDb.createObjectStore(TOKEN);
    }
  },
});

export const setIDBAuthValue = async (
  key: IDBKeyRange | IDBValidKey | undefined,
  val: string
) => {
  return (await dbAuthPromise).put(TOKEN, val, key);
};

export const getIDBAuthValue = async (key: IDBKeyRange | IDBValidKey) => {
  return (await dbAuthPromise).get(TOKEN, key);
};
