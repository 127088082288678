import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserRoles } from '../models';

export const AUTH = 'AUTH';

export interface Profile {
  userUid: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

interface AuthState {
  userProfile: Profile | null;
  roles: UserRoles[] | null;
}

const initialState: AuthState = {
  userProfile: null,
  roles: null,
};

const authSlice = createSlice({
  name: AUTH,
  initialState,
  reducers: {
    setUserProfile: (
      state: AuthState,
      action: PayloadAction<Profile | null>
    ) => {
      state.userProfile = action.payload;
    },
    setUserRoles: (
      state: AuthState,
      action: PayloadAction<UserRoles[] | null>
    ) => {
      state.roles = action.payload;
    },
  },
});

export const { setUserProfile, setUserRoles } = authSlice.actions;

export default authSlice.reducer;
