import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { IconButton, Popover } from '@mui/material';
import { MRT_ColumnDef } from 'material-react-table';

import { paths, routes } from 'src/app/routes';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { ErrorResponse } from 'src/shared/models';
import Icon from 'src/shared/components/Icon/Icon';
import Card from 'src/shared/components/Card/Card';
import Tabs from 'src/shared/components/Tabs/Tabs';
import Table from 'src/shared/components/Table/Table';
import { IconType } from 'src/shared/components/Icon/IconType';
import Dropdown from 'src/shared/components/Dropdown/Dropdown';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';
import {
  DATE_WITHOUT_TIMEZONE,
  downloadDocument,
  openDocumentWithAuthorization,
} from 'src/shared/utils';

import {
  GUESTApplicationStatus,
  CoCRequest,
} from 'src/features/cocRequests/models';
import { State } from 'src/features/store/store';
import { useCheckError } from 'src/features/hooks';
import { UserRoles } from 'src/features/auth/models';
import { AUTH } from 'src/features/auth/slices/authSlice';
import CertificatesStatus from 'src/features/cocRequests/components/CertificatesStatus';
import { useRevokeCredentialMutation } from 'src/features/credentials/api/credentialsApi';
import { useGetGUESTApplicationsQuery } from 'src/features/cocRequests/api/cocRequestsApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './CoCRequests.scss';

const TAB_STATUSES = {
  [routes.statusApproved]: 1,
};

const CoCRequests = () => {
  const navigate = useNavigate();
  const params = useSearchParams();
  const [search, setSearch] = useState<string>('');
  const [requests, setRequests] = useState<CoCRequest[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(
    TAB_STATUSES[params[0].get('status') as string] ?? 0
  );
  const [revoke, revokeResult] = useRevokeCredentialMutation();
  const userRoles = useSelector((s: State) => s[AUTH].roles);

  const STATUSES = [
    [
      GUESTApplicationStatus.DOCUMENTS_UPLOADED,
      GUESTApplicationStatus.APPLICATION_PAYMENT_SUCCESS,
      GUESTApplicationStatus.DOCUMENTS_REVISION,
    ],
    [
      GUESTApplicationStatus.APPROVED,
      GUESTApplicationStatus.ISSUED,
      GUESTApplicationStatus.CERTIFICATE_PAYMENT_FAILED,
      GUESTApplicationStatus.CERTIFICATE_PAYMENT_SUCCESS,
      GUESTApplicationStatus.CERTIFICATE_PAYMENT_PROCESSING,
    ],
  ];

  const {
    data: requestsApplications,
    isError,
    refetch,
  } = useGetGUESTApplicationsQuery(STATUSES[currentTab]);

  const dropdownItemsApproved = useMemo(
    () => (request: CoCRequest) => {
      const detailsElement = {
        text: 'Credential details',
        icon: <Icon icon={IconType.Send} />,
        onClick: () => navigate(paths.cocRequestById(request.applicationUid)),
      };

      const downloadCertificateElement = {
        text: 'Download certificate',
        icon: <Icon icon={IconType.Download} />,
        onClick: () =>
          request.certificateUid && downloadDocument(request.certificateUid),
        disabled: () => !request.certificateUid,
      };

      const firstReceiptElement = {
        text: 'Download receipt 1',
        icon: <Icon icon={IconType.Download} />,
        onClick: () =>
          request.firstPaymentUid &&
          openDocumentWithAuthorization(
            `${process.env.REACT_APP_API_URL}/guest-application/${request.applicationUid}/payment-receipt/${request.firstPaymentUid}?preview=true`
          ),
        disabled: () => !request.firstPaymentUid,
      };

      const secondReceiptElement = {
        text: 'Download receipt 2',
        icon: <Icon icon={IconType.Download} />,
        onClick: () =>
          request.secondPaymentUid &&
          openDocumentWithAuthorization(
            `${process.env.REACT_APP_API_URL}/guest-application/${request.applicationUid}/payment-receipt/${request.secondPaymentUid}?preview=true`
          ),
        disabled: () => !request.secondPaymentUid,
      };

      const revokeElement = {
        text: 'Revoke CoC',
        icon: <Icon icon={IconType.Cross} />,
        onClick: () =>
          request.certificateUid &&
          revoke(request.certificateUid).then(() => refetch()),
        disabled: () =>
          !request.certificateUid ||
          !(
            userRoles?.includes(UserRoles.ISSUER) ||
            userRoles?.includes(UserRoles.ISSUER_ADMIN)
          ),
      };

      if (request.status === GUESTApplicationStatus.ISSUED) {
        return [
          detailsElement,
          downloadCertificateElement,
          firstReceiptElement,
          secondReceiptElement,
          revokeElement,
        ];
      }

      if (
        request.status === GUESTApplicationStatus.DOCUMENTS_UPLOADED ||
        request.status === GUESTApplicationStatus.DOCUMENTS_REVISION ||
        request.status === GUESTApplicationStatus.APPROVED ||
        request.status === GUESTApplicationStatus.CERTIFICATE_PAYMENT_FAILED
      ) {
        return [detailsElement, firstReceiptElement];
      }

      return [detailsElement];
    },
    [navigate, refetch, revoke, userRoles]
  );

  const TABS = useMemo(
    () => [
      {
        label: 'Pending Validation',
        path: paths.cocRequests,
      },
      {
        label: 'Approved',
        path: paths.cocRequestsApproved,
        items: (request: CoCRequest) => dropdownItemsApproved(request),
      },
    ],
    [dropdownItemsApproved]
  );

  useCheckError(isError, 'Error loading CoC Requests');
  useCheckError(
    revokeResult.isError,
    `Error revoking the request: ${
      revokeResult.error
        ? (revokeResult.error as unknown as ErrorResponse).data.message
        : ''
    }`
  );

  useEffect(() => {
    if (revokeResult.isSuccess) {
      toast.success('GUEST Revoked Successfuly', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [revokeResult]);

  const filterRequests = useCallback(
    () =>
      requestsApplications
        ? requestsApplications.data.filter(
            (request) =>
              request.firstName.toLowerCase().includes(search.toLowerCase()) ||
              request.middleName.toLowerCase().includes(search.toLowerCase()) ||
              request.lastName.toLowerCase().includes(search.toLowerCase()) ||
              request.email.toLowerCase().includes(search.toLowerCase())
          )
        : [],
    [search, requestsApplications]
  );

  useEffect(() => {
    setRequests(filterRequests);
  }, [filterRequests, search]);

  const handleTabClick = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  const baseColumns: MRT_ColumnDef[] = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Date',
        accessorKey: 'createdAt',
        size: 80,
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
      },
      {
        header: 'First name',
        accessorKey: 'firstName',
        size: 100,
      },
      {
        header: 'Middle name',
        accessorKey: 'middleName',
        size: 100,
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
        size: 100,
      },
      {
        header: 'Email',
        accessorKey: 'email',
        size: 100,
      },
      {
        header: 'Type of CoC',
        accessorKey: 'type',
      },
    ],
    []
  );

  const detailedViewColumn: MRT_ColumnDef = useMemo<MRT_ColumnDef>(() => {
    return {
      header: '',
      id: 'form',
      size: 50,
      Cell: ({ row }) => {
        return (
          <NavLink
            to={paths.cocRequestById(
              (row.original as CoCRequest).applicationUid
            )}
          >
            <Icon icon={IconType.ChevronSquare} />
          </NavLink>
        );
      },
    };
  }, []);

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      ...baseColumns,
      {
        header: 'Certificates & Documents',
        accessorKey: 'status',
        Cell: ({ cell }) => (
          <CertificatesStatus value={cell.getValue<GUESTApplicationStatus>()} />
        ),
      },
      detailedViewColumn,
    ],
    [baseColumns, detailedViewColumn]
  );

  const columnsApproved = useMemo<MRT_ColumnDef[]>(
    () => [
      ...baseColumns,
      {
        header: 'Payment status',
        accessorKey: 'status',
        Cell: ({ cell }) => (
          <CertificatesStatus value={cell.getValue<GUESTApplicationStatus>()} />
        ),
      },
      {
        header: '',
        id: 'actions',
        size: 40,
        maxSize: 40,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown
                  elements={
                    TABS[currentTab].items?.(row.original as CoCRequest) ?? []
                  }
                  element={row.original as CoCRequest}
                  callbackOnClick={handleClose}
                />
              </Popover>
            </>
          );
        },
      },
      detailedViewColumn,
    ],
    [TABS, baseColumns, currentTab, detailedViewColumn]
  );

  const [columnOrder] = useState(
    columnsApproved.map((c) => c.accessorKey ?? c.id ?? '')
  );

  return (
    <Page>
      <div className="coc-requests">
        <h1>GUEST CoC requests</h1>
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="coc-requests__table-container">
          <Tabs value={currentTab} values={TABS} onChange={handleTabClick} />
          <Table<CoCRequest>
            columns={currentTab === 1 ? columnsApproved : columns}
            data={requests}
            enableSorting
            initialState={{
              sorting: [
                {
                  id: 'createdAt',
                  desc: true,
                },
              ],
              columnOrder: columnOrder,
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default CoCRequests;
