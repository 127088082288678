import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MODALS } from 'src/features/modal/models';

export const MODAL = 'MODAL';

interface ModalState {
  openedModal: MODALS | null;
}

const initialState: ModalState = {
  openedModal: null,
};

const modalSlice = createSlice({
  name: MODAL,
  initialState,
  reducers: {
    changeModal: (state: ModalState, action: PayloadAction<MODALS | null>) => {
      state.openedModal = action.payload;
    },
  },
});

export const { changeModal } = modalSlice.actions;

export default modalSlice.reducer;
