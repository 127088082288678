export enum CampaignStatus {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  FAILED = 'FAILED',
  DELETED = 'DELETED',
}

export interface Campaign {
  uid: string;
  title: string;
  message: string;
  userCompanyUid: string;
  status?: CampaignStatus;
  openRate?: number;
  totalAmountOfUsers?: number;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
  publishedAt?: string;
}

export interface CampaignUser {
  name: string;
  email: string;
}

export interface GetCampaignUsersResponse {
  data: CampaignUser[];
}

export interface GetCampaignsRequest {
  size?: number;
  page?: number;
}

export interface GetCampaignsResponse {
  data: Campaign[];
  count: number;
}

export interface TargetCriteria {
  credentialTemplates: string[];
}

export interface CreateCampaignRequest {
  uid?: string;
  title: string;
  message: string;
  targetCriteria?: TargetCriteria;
}
