import { Divider } from '@mui/material';
import { useState } from 'react';
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { useGetAggregationQuery } from 'src/features/analytics/api/analyticsApi';
import { useCheckError } from 'src/features/hooks';

import ChartFilterButtons, {
  BUTTONS_TYPE,
} from '../ChartFilterButtons/ChartFilterButtons';

import './TimeChart.scss';

enum TABS {
  TOTAL = 'Total',
  TODAY = 'Today',
  WEEK = 'Week',
  MONTH = 'Month',
}

const TimeChart = () => {
  const [selectedTab, setSelectedTab] = useState<TABS>(TABS.TODAY);
  const { data: aggregation, isError } = useGetAggregationQuery();
  useCheckError(isError, 'Error loading Aggregations');

  const FILTERS = [
    {
      name: TABS.TOTAL,
      onClick: () => setSelectedTab(TABS.TOTAL),
      selected: selectedTab === TABS.TOTAL,
    },
    {
      name: TABS.TODAY,
      onClick: () => setSelectedTab(TABS.TODAY),
      selected: selectedTab === TABS.TODAY,
    },
    {
      name: TABS.WEEK,
      onClick: () => setSelectedTab(TABS.WEEK),
      selected: selectedTab === TABS.WEEK,
    },
    {
      name: TABS.MONTH,
      onClick: () => setSelectedTab(TABS.MONTH),
      selected: selectedTab === TABS.MONTH,
    },
  ];

  const data = {
    [TABS.TOTAL]: [{ name: 'Total', value: aggregation?.data.TOTAL }],
    [TABS.TODAY]: aggregation?.data.DAY ?? [],
    [TABS.WEEK]: aggregation?.data.WEEK ?? [],
    [TABS.MONTH]: aggregation?.data.MONTH ?? [],
  };

  return (
    <>
      <ChartFilterButtons filters={FILTERS} type={BUTTONS_TYPE.TEXT} />
      <Divider />
      <ResponsiveContainer width="100%" height="90%">
        <Chart
          data={data[selectedTab]}
          margin={{
            top: 20,
            right: 50,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis dataKey="name" style={{ fontSize: '14px' }} />
          <YAxis allowDecimals={false} style={{ fontSize: '14px' }} />
          <Tooltip />
          <Bar
            dataKey="value"
            stackId="a"
            fill="var(--ncap-600)"
            radius={[4, 4, 0, 0]}
          />
        </Chart>
      </ResponsiveContainer>
    </>
  );
};

export default TimeChart;
