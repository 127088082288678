import { ReactNode } from 'react';

import './Page.scss';

type PageProps = {
  children: ReactNode;
};

const Page = ({ children }: PageProps) => {
  return <div className="page">{children}</div>;
};

export default Page;
