import { ReactNode } from 'react';

import './Card.scss';

type CardProps = {
  children: ReactNode;
  header?: string;
  className?: string;
};

const Card = ({ children, header, className }: CardProps) => {
  return (
    <div className={`card ${className ? className : ''}`}>
      {header ? <h2 className="card__header">{header}</h2> : null}
      {children}
    </div>
  );
};

export default Card;
