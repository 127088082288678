import { CredentialTemplateField } from '../credentials/models';

export interface Template {
  uid: string;
  name: string;
  description: string;
  image: string;
  renderParams: Record<string, CredentialTemplateField>;
  criteria?: string;
  tags?: string[] | null;
  type?: string;
  userId?: string;
  userLogin?: string;
  designTemplateUid: string;
}

export type GetTemplatesResponse = {
  data: Template[];
};

export interface CreateTemplateRequest {
  name: string;
  description: string;
  image: string;
}

export interface UpdateTemplateRequest {
  id: string;
  name: string;
  description: string;
  image: string;
}

export const enum LabelTypes {
  NUMBER = 'number',
  DATE = 'date',
  TEXT = 'text',
  FIXED_TEXT = 'fixedText',
  SHORT_TEXT = 'shortText',
  BOOLEAN = 'boolean',
  IMAGE = 'image',
}
