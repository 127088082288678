import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  palette: {
    primary: {
      main: '#08B8DF',
    },
    error: {
      main: '#EB0000',
    },
    secondary: {
      main: '#333333',
    },
    info: {
      main: '#E5E5E5',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        filled: {
          padding: '4px 12px 4px 14px',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
          '&.payment-status_paid': {
            backgroundColor: '#ECFAFD',
          },
          '&.payment-status_pending': {
            backgroundColor: '#FEF3E2',
          },
          '&.payment-status_denied': {
            backgroundColor: '#FAE3E3',
          },
          '&.coc-status_active': {
            width: '95%',
            backgroundColor: '#ECFAFD',
          },
          '&.coc-status_not-registered': {
            padding: '4px 0',
            width: '95%',
            backgroundColor: '#F7D9D9',
          },
          '&.certificates-status_received': {
            backgroundColor: '#ECFAFD',
          },
          '&.certificates-status_pending': {
            backgroundColor: '#ECFAFD',
          },
          '&.account-status_confirmed': {
            padding: '4px 0 4px 4px',
            backgroundColor: '#F7F7F7',
          },
          '&.account-status_confirmed path': {
            fill: '#666666',
          },
          '&.account-status_not-confirmed': {
            padding: '4px 0 4px 4px',
            backgroundColor: '#FCE3E3',
          },
          '&.account-status_not-confirmed path': {
            fill: '#B61A1A',
          },
          '&.campaign-status_draft': {
            backgroundColor: '#FEF3E2',
          },
          '&.campaign-status_draft path': {
            fill: '#F59E0B',
          },
          '&.campaign-status_sent': {
            backgroundColor: '#D1F0D2',
          },
          '&.campaign-status_sent path': {
            fill: '#66BB6A',
          },
          '&.campaign-status_failed': {
            backgroundColor: '#FCE3E3',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          width: 'fit-content',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '16px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'unset',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          position: 'relative',
          '&:after': {
            position: 'absolute',
            bottom: 0,
            width: '80%',
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
            content: '""',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.search-bar': {
            width: '100%',
          },
          width: 'fit-content',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',

          '&.MuiTableCell-head': {
            verticalAlign: 'middle',
          },
          '& .button_row-actions': {
            padding: '10px 16px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          fontWeight: 800,
          lineHeight: '24px',

          '& .Mui-TableHeadCell-Content-Wrapper': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.sst-request__button_actions': {
            padding: '16px 8px',
          },
          '&.primary-button_info': {
            padding: '6px',
            borderRadius: '8px',
            color: theme.palette.secondary.main,
            backgroundColor: '#F3F3F3',
          },
          '&.notification-preview__button': {
            padding: '16px',
            borderRadius: '8px',
            backgroundColor: '#ECFAFD',
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: '8px 16px',
          textTransform: 'none',
          boxShadow: 'none',
          borderRadius: '8px',

          fontSize: '16px',
          fontWeight: 600,
          lineHeight: '24px',

          '&.settings__form-button': {
            padding: '8px 40px',
            height: '40px',
            color: '#05738C',
            backgroundColor: '#ECFAFD',
          },
          '&.settings__form-button_secondary': {
            padding: '8px 40px',
            height: '40px',
            color: theme.palette.secondary.main,
            backgroundColor: '#F3F3F3',
          },
          '&.primary-button': {
            padding: '6px 20px',
            height: '40px',
            color: '#05738C',
            backgroundColor: '#ECFAFD',
          },
          '&.filter-button': {
            color: '#000000',
          },
          '&.template-preview__button': {
            padding: '6px 20px',
            lineHeight: '20px',
            color: '#05738C',
            backgroundColor: '#ECFAFD',
          },
          '&.template-preview__button_info': {
            padding: '6px 20px',
            lineHeight: '20px',
            color: theme.palette.secondary.main,
            backgroundColor: '#F3F3F3',
          },
          '&.settings__form-button_error': {
            padding: '8px 40px',
            height: '40px',
            color: theme.palette.error.main,
            backgroundColor: '#FCE3E3',
          },
          '&.settings__add-button': {
            padding: '22px 0',
            color: theme.palette.secondary.main,
            backgroundColor: '#F3F3F3',
            border: '1px dashed #D5D5D5',
            fontSize: '16px',
            fontWeight: 800,
            lineHeight: '24px',
            width: '50%',
          },
          '& .file-uploader__button': {
            padding: '8px 40px',
            lineHeight: '24px',
            color: '#05738C',
            backgroundColor: '#ECFAFD',
          },
          '&.coc-request-detailed__download-button': {
            marginTop: '16px',
          },
        }),
        contained: {
          color: '#ffffff',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: 1000,
        },
        paper: {
          padding: '16px',
          borderRadius: '8px',
        },
        paperWidthLg: {
          width: '1050px',
          padding: '24px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '16px 0',
          color: '#242424',

          fontSize: '20px',
          fontWeight: 800,
          lineHeight: '24px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '16px 0',
          overflowY: 'unset',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#242424',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '24px',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 0',

          '& > .MuiButtonBase-root': {
            width: '50%',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiInputBase-root': {
            border: `1px solid ${theme.palette.info.main}`,
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
            width: '100%',
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#B3B3B3',

            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '24px',
          },
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
            {
              border: 'none',
            },
          '& .MuiInputBase-root.Mui-disabled': {
            backgroundColor: '#F7F7F7',
          },
          '&.date-picker__input > .MuiInputBase-root': {
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingRight: 0,
            '& > input': {
              padding: '12px 10px',
            },
          },
          '&.date-range__input': {
            width: '160px',
          },
          '&.date-range__input > .MuiInputBase-root': {
            display: 'flex',
            flexDirection: 'row-reverse',
            paddingRight: 0,
            '& > input': {
              padding: '10px 8px',
            },
          },
        }),
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: '#242424',
        },
        separator: {
          fontWeight: 800,
          fontSize: '28px',
          lineHeight: '32px',
          color: '#B3B3B3',
          '& + a:hover': {
            color: '#666666',
          },
        },
        li: {
          '& a': {
            textDecoration: 'none',
            fontWeight: 800,
            fontSize: '28px',
            lineHeight: '32px',
          },
          '& a:hover': {
            color: '#666666',
          },
          '& h1': {
            textDecoration: 'none',
            fontWeight: 800,
            fontSize: '28px',
            lineHeight: '32px',
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontWeight: 800,
          fontSize: '16px',
          lineHeight: '24px',
          '&.Mui-active, &.Mui-completed, &.Mui-disabled': {
            fontWeight: 800,
            fontSize: '16px',
            lineHeight: '24px',
            color: '#242424',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderColor: '#D5D5D5',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          width: '40px',
          height: '40px',
          fill: theme.palette.info.main,
          '&.Mui-active': {
            fill: '#005977',
          },
          '&.Mui-completed': {
            fill: theme.palette.primary.main,
          },
        }),
        text: {
          fill: '#FFFFFF',
          fontWeight: 800,
          lineHeight: '24px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.upload-guest-documents__agreement .MuiTypography-root': {
            fontSize: theme.breakpoints.up(500) ? '12px' : '16px',
          },
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 700,
          lineHeight: '24px',
          color: '#242424',
        },
      },
    },
  },
});
