import { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { paths } from 'src/app/routes';
import defaultPicture from 'src/shared/components/PicturePicker/images/default.png';

import { MODALS } from 'src/features/modal/models';
import { UserRoles } from 'src/features/auth/models';
import { decodeProfileInfo, rolesEquals } from 'src/features/auth/utils';
import { State, store } from 'src/features/store/store';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import {
  NotificationObject,
  getIDBValue,
} from 'src/features/notifications/firebase-config';

import { ReactComponent as DashboardIcon } from './images/dashboard-icon.svg';
import { ReactComponent as CardIcon } from './images/card-icon.svg';
import { ReactComponent as ShieldIcon } from './images/shield-icon.svg';
import { ReactComponent as PencilIcon } from './images/pencil-icon.svg';
import { ReactComponent as PersonIcon } from './images/person-icon.svg';
import { ReactComponent as UserIcon } from './images/user-icon.svg';
import { ReactComponent as ChevronIcon } from './images/chevron-icon.svg';
import { ReactComponent as DocumentIcon } from './images/document-icon.svg';
import { ReactComponent as NotificationIcon } from './images/notification-icon.svg';
import { ReactComponent as SpeakerIcon } from './images/speaker-icon.svg';
import Logo from './images/ncap-logo.png';

import './Sidebar.scss';

type SidebarItemProps = {
  isActive: boolean;
  isPending: boolean;
};

const SUPPORT_LINK = 'https://n-cap.freshdesk.com/support/tickets/new';

const setClassName = ({ isActive, isPending }: SidebarItemProps) =>
  isActive ? 'sidebar-item__link_active' : isPending ? 'pending' : '';

const Sidebar = () => {
  const userRoles = useSelector((s: State) => s[AUTH].roles);
  const userProfile = useSelector((s: State) => s[AUTH].userProfile);

  const [notifications, setNotifications] = useState<NotificationObject[]>([]);

  const authToken = localStorage.getItem('ncap-token') ?? '';
  const profileInfo = decodeProfileInfo(authToken);

  const profilePicture = `${process.env.REACT_APP_PROFILE_PICTURE_URL}/${userProfile?.userUid}`;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnError = (e: any) => {
    e.target.src = defaultPicture;
  };

  const getNotifications = () => {
    if (profileInfo.userUid) {
      getIDBValue(profileInfo.userUid).then(
        (notifications: { notifications: NotificationObject[] }) =>
          notifications
            ? setNotifications(
                notifications.notifications.filter(
                  (notification) => !notification.read
                )
              )
            : setNotifications([])
      );
    }
  };

  getNotifications();

  return (
    <aside className="sidebar">
      <div>
        <div className="ncap-logo">
          <img src={Logo} alt="ncap-logo" width="110vw" height="40vh" />
        </div>
        <ul className="sidebar__navigation">
          <li className="sidebar__item">
            <NavLink to={paths.dashboard} className={setClassName}>
              <div className="sidebar__link-icon">
                <DashboardIcon />
              </div>
              Dashboard
            </NavLink>
          </li>
          {userRoles &&
          (userRoles.includes(UserRoles.PYA) ||
            userRoles.includes(UserRoles.PYA_ADMIN) ||
            userRoles.includes(UserRoles.NAVOZYME_ADMIN)) ? (
            <li className="sidebar__item">
              <NavLink to={paths.requests} className={setClassName}>
                <div className="sidebar__link-icon">
                  <PencilIcon />
                </div>
                SST Requests
              </NavLink>
            </li>
          ) : null}
          {userRoles && userRoles.includes(UserRoles.MARKETING) ? (
            <li className="sidebar__item">
              <NavLink to={paths.campaigns} className={setClassName}>
                <div className="sidebar__link-icon">
                  <SpeakerIcon />
                </div>
                NCap™ Engage
              </NavLink>
            </li>
          ) : null}
          {userRoles && userRoles.includes(UserRoles.GUEST) ? (
            <li className="sidebar__item sidebar__item_guest-coc-requests">
              <NavLink to={paths.cocRequests} className={setClassName}>
                <div className="sidebar__link-icon">
                  <DocumentIcon />
                </div>
                GUEST CoC requests
              </NavLink>
            </li>
          ) : null}
          {userRoles && userRoles.includes(UserRoles.GUEST) ? (
            <li className="sidebar__item">
              <NavLink to={paths.cocPayments} className={setClassName}>
                <div className="sidebar__link-icon">
                  <CardIcon />
                </div>
                GUEST payments
              </NavLink>
            </li>
          ) : null}

          {!rolesEquals(userRoles ?? [], [UserRoles.PYA]) &&
            !rolesEquals(userRoles ?? [], [UserRoles.PYA_ADMIN]) && (
              <li className="sidebar__item">
                <NavLink to={paths.credentials} className={setClassName}>
                  <div className="sidebar__link-icon">
                    <ShieldIcon />
                  </div>
                  Credentials
                </NavLink>
              </li>
            )}
          {userRoles &&
          (userRoles.includes(UserRoles.PYA) ||
            userRoles.includes(UserRoles.PYA_ADMIN) ||
            userRoles.includes(UserRoles.NAVOZYME_ADMIN)) ? (
            <li className="sidebar__item">
              <NavLink to={paths.responsiblePersons} className={setClassName}>
                <div className="sidebar__link-icon">
                  <PersonIcon />
                </div>
                Responsible persons
              </NavLink>
            </li>
          ) : null}
          {userRoles && userRoles.includes(UserRoles.NAVOZYME_ADMIN) ? (
            <li className="sidebar__item">
              <NavLink to={paths.mobileUsers} className={setClassName}>
                <div className="sidebar__link-icon">
                  <UserIcon />
                </div>
                Users
              </NavLink>
            </li>
          ) : null}
        </ul>
      </div>
      <ul className="sidebar__user-options">
        <li className="sidebar-user-options__item sidebar-user-options__item_user-info">
          <div className="sidebar__user-info">
            <img
              src={profilePicture}
              onError={handleOnError}
              alt="User picture"
              className="sidebar-user-info__picture"
              width="40px"
              height="40px"
            />
            <div className="sidebar-user-info__container">
              <p className="sidebar-user-info__profile">Profile</p>
              <p className="sidebar-user-info__name">
                {`${profileInfo.firstName ?? ''} ${profileInfo.lastName ?? ''}`}
              </p>
            </div>
          </div>
        </li>
        <li className="sidebar-user-options__item">
          <NavLink to={paths.settings} className={setClassName}>
            Settings
            <div className="sidebar__link-icon">
              <ChevronIcon />
            </div>
          </NavLink>
        </li>
        <li className="sidebar-user-options__item">
          <a href={SUPPORT_LINK} target="_blank" rel="noreferrer">
            Support
            <div className="sidebar__link-icon">
              <ChevronIcon />
            </div>
          </a>
        </li>
        {!rolesEquals(userRoles ?? [], [UserRoles.ISSUER]) && (
          <li className="sidebar-user-options__item">
            <NavLink to={paths.notifications} className={setClassName}>
              Notifications
              <div className="sidebar__link-icon sidebar__notifications-icons">
                {notifications.length !== 0 ? (
                  <NotificationIcon className="sidebar__link-icon_notification" />
                ) : null}
                <ChevronIcon />
              </div>
            </NavLink>
          </li>
        )}
        <li className="sidebar-user-options__item">
          <button
            className="sidebar-user-options__logout"
            onClick={() => store.dispatch(changeModal(MODALS.LOGOUT))}
          >
            <p>Log out</p>
            <div className="sidebar__link-icon">
              <ChevronIcon />
            </div>
          </button>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
