import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { store } from 'src/features/store/store';
import FirebaseNotification from 'src/features/notifications/components/FirebaseNotification/FirebaseNotification';
import NotificationContainer from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import Router from './Router';

const App = (): JSX.Element => {
  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router />
        <NotificationContainer />
        <FirebaseNotification />
      </LocalizationProvider>
    </Provider>
  );
};

export default App;
