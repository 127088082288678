import { Chip } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { GUESTApplicationStatus } from '../models';

type CertificatesStatusProps = {
  value: GUESTApplicationStatus;
};

const STATUSES = {
  [GUESTApplicationStatus.DOCUMENTS_UPLOADED]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Received',
    className: 'certificates-status_received',
  },
  [GUESTApplicationStatus.DOCUMENTS_REVISION]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'certificates-status_pending',
  },
  [GUESTApplicationStatus.APPLICATION_PAYMENT_SUCCESS]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'certificates-status_pending',
  },

  [GUESTApplicationStatus.ISSUED]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Paid',
    className: 'certificates-status_pending',
  },
  [GUESTApplicationStatus.APPROVED]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'certificates-status_pending',
  },
  [GUESTApplicationStatus.CERTIFICATE_PAYMENT_FAILED]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'certificates-status_pending',
  },
  [GUESTApplicationStatus.CERTIFICATE_PAYMENT_SUCCESS]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Paid',
    className: 'certificates-status_received',
  },
  [GUESTApplicationStatus.CERTIFICATE_PAYMENT_PROCESSING]: {
    icon: <Icon icon={IconType.PendingCircle} />,
    label: 'Pending',
    className: 'certificates-status_pending',
  },

  [GUESTApplicationStatus.REJECTED]: {
    icon: <Icon icon={IconType.CrossCircle} />,
    label: 'Rejected',
    className: 'certificates-status_pending',
  },
};

const CertificatesStatus = ({ value }: CertificatesStatusProps) => {
  return (
    <Chip
      icon={STATUSES[value].icon}
      label={STATUSES[value].label}
      className={STATUSES[value].className}
    />
  );
};

export default CertificatesStatus;
