export interface SignInRequest {
  email: string;
  password: string;
}

export interface SignInResponse {
  data: {
    token: string;
  };
}

export interface AccountData {
  id: number;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  imageUrl: string | null;
  activated: boolean;
  langKey: string;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  authorities: string[];
  userUid: string | null;
}

export interface ProfileInfo {
  userUid: string | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  birthDate?: string;
  phone?: string;
  photo?: string;
}

export enum UserRoles {
  SEAFARER = 'SEAFARER',
  RP = 'RP',
  NAVOZYME_ADMIN = 'NAVOZYME_ADMIN',
  PYA = 'PYA',
  PYA_ADMIN = 'PYA_ADMIN',
  ISSUER_BASIC = 'ISSUER_BASIC',
  ISSUER = 'ISSUER',
  ISSUER_ADMIN = 'ISSUER_ADMIN',
  GUEST = 'GUEST',
  MARKETING = 'MARKETING',
}

export interface UserProfile {
  userUid: string;
  roles: UserRoles[];
  firstName?: string;
  lastName?: string;
}

export interface GetMyProfileResponse {
  data: UserProfile;
}

export interface ResetPasswordRequest {
  email: string;
}

export interface ResetPasswordConfirmRequest {
  email: string;
  verificationCode: string;
  newPassword: string;
}

export interface ChangePasswordRequest {
  userUid: string;
  currentPassword: string;
  newPassword: string;
}
