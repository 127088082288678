import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import { ErrorResponse } from 'src/shared/models';
import Modal from 'src/shared/components/Modal/Modal';

import { State, store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { TestimonialRequestStatus } from 'src/features/testimonials/models';
import { useUpdateTestimonialMutation } from 'src/features/testimonials/api/testimonialsApi';
import {
  setRequestId,
  TESTIMONIAL,
} from 'src/features/testimonials/slices/testimonialSlice';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import { ReactComponent as ReturnIcon } from './images/return-icon.svg';

const ReturnToRP = () => {
  const navigate = useNavigate();
  const requestId = useSelector((s: State) => s[TESTIMONIAL].requestId);
  const [updateTestimonial, result] = useUpdateTestimonialMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (result.isSuccess) {
      closeModal();
      navigate(paths.requests);
      toast.success('SST Returned Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result]);

  return (
    <Modal
      open={true}
      title="Are you sure you want to return the SST back to the responsible person?"
      text="Please tell them the reason why."
      inputField
      confirmButtonText="Return the SST"
      onBackClick={closeModal}
      onConfirmClick={(message?: string) =>
        requestId &&
        updateTestimonial({
          uid: requestId,
          status: TestimonialRequestStatus.REVISION_RP,
          rejectionReason: message ?? '',
        })
      }
      icon={<ReturnIcon />}
    />
  );
};

export default ReturnToRP;
