import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import { addAuthorizationHeaderWithBearer } from 'src/shared/api/utils';
import { DefaultResponse } from 'src/shared/models';

import {
  CreateResponsiblePersonRequest,
  GetResponsiblePersonByIdResponse,
  GetResponsiblePersonsResponse,
} from '../models';

export const RESPONSIBLE_PERSONS_API = 'RESPONSIBLE_PERSONS_API';

const responsiblePersonsApi = createApi({
  reducerPath: RESPONSIBLE_PERSONS_API,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: process.env.REACT_APP_API_URL,
      prepareHeaders: (headers) => addAuthorizationHeaderWithBearer(headers),
    }),
    {
      maxRetries: 0,
    }
  ),
  tagTypes: ['RESPONSIBLE_PERSONS'],
  endpoints: (builder) => ({
    getResponsiblePersons: builder.query<GetResponsiblePersonsResponse, void>({
      query: () => ({
        method: 'GET',
        url: 'responsible-person',
      }),
      providesTags: ['RESPONSIBLE_PERSONS'],
    }),
    getResponsiblePersonById: builder.query<
      GetResponsiblePersonByIdResponse,
      string
    >({
      query: (responsiblePersonId) => ({
        method: 'GET',
        url: `responsible-person/${responsiblePersonId}`,
      }),
    }),
    createResponsiblePerson: builder.mutation<
      DefaultResponse,
      CreateResponsiblePersonRequest
    >({
      query: (request) => ({
        method: 'POST',
        url: 'responsible-person',
        body: request,
      }),
      invalidatesTags: ['RESPONSIBLE_PERSONS'],
    }),
    updateResponsiblePersonById: builder.mutation<
      DefaultResponse,
      CreateResponsiblePersonRequest & { id: string }
    >({
      query: (request) => ({
        method: 'PUT',
        url: `responsible-person/${request.id}`,
        body: request,
      }),
      invalidatesTags: ['RESPONSIBLE_PERSONS'],
    }),
  }),
});

export const {
  useGetResponsiblePersonsQuery,
  useGetResponsiblePersonByIdQuery,
  useCreateResponsiblePersonMutation,
  useUpdateResponsiblePersonByIdMutation,
} = responsiblePersonsApi;

export const responsiblePersonsMiddleware = responsiblePersonsApi.middleware;

export default responsiblePersonsApi.reducer;
