import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const COC_REQUEST = 'COC_REQUEST';

interface CoCRequestState {
  requestId: string | null;
}

const initialState: CoCRequestState = {
  requestId: null,
};

const cocRequestsSlice = createSlice({
  name: COC_REQUEST,
  initialState,
  reducers: {
    setRequestId: (
      state: CoCRequestState,
      action: PayloadAction<string | null>
    ) => {
      state.requestId = action.payload;
    },
  },
});

export const { setRequestId } = cocRequestsSlice.actions;

export default cocRequestsSlice.reducer;
