/* eslint-disable react/jsx-one-expression-per-line */
import dayjs from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
import { FileUploader as Uploader } from 'react-drag-drop-files';
import readXlsxFile from 'read-excel-file';

import { countries, DATE_FORMAT } from 'src/shared/utils';
import Icon from 'src/shared/components/Icon/Icon';
import Modal from 'src/shared/components/Modal/Modal';
import { IconType } from 'src/shared/components/Icon/IconType';
import FilePreview from 'src/shared/components/FilePreview/FilePreview';

import { store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { CredentialRecipientDetails } from 'src/features/credentials/models';
import { addCredentialDetails } from 'src/features/credentials/slices/credentialsSlice';

import './ImportSpreadsheet.scss';

const FILE_TYPES = ['XLSX'];
const MAX_SIZE = 6;

interface ValidationError {
  error: string;
  reason?: string;
  row: number;
  column: string;
  value?: unknown;
}

const XLSX_SCHEMA = {
  Name: {
    prop: 'firstName',
    type: String,
    required: true,
  },
  'Last name': {
    prop: 'lastName',
    type: String,
    required: true,
  },
  Email: {
    prop: 'email',
    type: String,
    required: (value: object) => !('secondaryIdentifier' in value),
  },
  'Nationality (Alpha-2 Format)': {
    prop: 'country',
    type: String,
    oneOf: countries.map((country) => country.value),
  },
  'National ID number': {
    prop: 'countryId',
    type: String,
  },
  'Birthdate (dd/mm/yyyy)': {
    prop: 'birthdate',
    type: Date,
  },
  'Internal Identifier': {
    prop: 'secondaryIdentifier',
    type: String,
    required: (value: object) => !('email' in value),
  },
  'Phone Number': {
    prop: 'phone',
    type: String,
  },
  Address: {
    prop: 'address',
    type: String,
  },
  'Issuance Date (dd/mm/yyyy)': {
    prop: 'issuedOn',
    type: Date,
    required: true,
  },
  'Expiration Date (dd/mm/yyyy)': {
    prop: 'expires',
    type: Date,
  },
  'Credential Identifier': {
    prop: 'externalIdentifier',
    type: String,
  },
  Comment: {
    prop: 'comment',
    type: String,
  },
};

enum ContentType {
  UPLOAD,
  UPLOAD_SUCCESS,
  UPLOAD_ERROR,
  VALIDATION_ERROR,
}

const ImportSpreadsheet = () => {
  const [file, setFile] = useState<File[]>([]);
  const [contentType, setContentType] = useState<ContentType>(
    ContentType.UPLOAD
  );
  const [validationErrors, setErrors] = useState<ValidationError[]>([]);

  const closeModal = () => {
    store.dispatch(changeModal(null));
  };

  const displayValidationErrors = useCallback(() => {
    const errors = validationErrors
      .filter((error) => error.column !== 'Internal Identifier')
      .map((error) => {
        if (error.column === 'Email') {
          return {
            ...error,
            column: 'Email or Internal Identifier',
          };
        }
        return error;
      });

    return errors.map((error, key) => (
      <p
        key={key}
        className="import-spreadsheet__error-text"
      >{`Row ${error.row}: the ${error.column} field is ${error.error}`}</p>
    ));
  }, [validationErrors]);

  const readFile = (file: File) => {
    readXlsxFile(file, { schema: XLSX_SCHEMA, ignoreEmptyRows: true })
      .then(({ rows, errors }) => {
        if (errors.length === 0) {
          const details: CredentialRecipientDetails[] = (
            rows as unknown as CredentialRecipientDetails[]
          ).map((elem) => {
            return {
              ...elem,
              issuedOn: dayjs(elem.issuedOn).format(DATE_FORMAT),
              birthdate: elem.birthdate
                ? dayjs(elem.birthdate).format(DATE_FORMAT)
                : undefined,
              expires: elem.expires
                ? dayjs(elem.expires).format(DATE_FORMAT)
                : undefined,
            };
          });
          store.dispatch(addCredentialDetails(details));
          setContentType(ContentType.UPLOAD_SUCCESS);
        } else {
          setErrors(errors);
          setContentType(ContentType.VALIDATION_ERROR);
        }
      })
      .catch(() => setContentType(ContentType.UPLOAD_ERROR));
  };

  const fileUploader = useMemo(
    () =>
      file.length ? (
        <FilePreview name={file[0].name} onCrossClick={() => setFile([])} />
      ) : (
        <div className="import-spreadsheet__content">
          <Uploader
            multiple={true}
            handleChange={(file: File[]) => setFile(file)}
            types={FILE_TYPES}
            classes="file-uploader"
            label="Click to browse or drag and drop your files"
            maxSize={MAX_SIZE}
          >
            <div className="file-uploader__content">
              <p>
                <span className="file-uploader__content_highlighted">{`Click `}</span>
                to browse
                <br /> or drag and drop your files
              </p>
            </div>
          </Uploader>
          <p className="file-uploader__template-link">
            Click to download the template{' '}
            <a href={process.env.REACT_APP_TEMPLATE_HREF}>here</a>
          </p>
        </div>
      ),
    [file]
  );

  const onUploadClick = useCallback(() => {
    if (file.length === 1) {
      readFile(file[0]);
    }
  }, [file]);

  const onTryAgainClick = () => {
    setFile([]);
    setContentType(ContentType.UPLOAD);
  };

  const contentMap = useMemo(() => {
    return {
      [ContentType.UPLOAD]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          onBackClick={closeModal}
          confirmButtonText="Upload"
          backButtonText="Cancel"
          onConfirmClick={onUploadClick}
          content={fileUploader}
        />
      ),
      [ContentType.UPLOAD_SUCCESS]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="File successfully uploaded"
          onBackClick={closeModal}
          backButtonText="Close"
          hideConfirmButton
          icon={
            <Icon
              icon={IconType.Verify}
              className="import-spreadsheet__success"
            />
          }
        />
      ),
      [ContentType.UPLOAD_ERROR]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="Failed to upload the file successfully"
          confirmButtonText="Try again"
          onConfirmClick={onTryAgainClick}
          backButtonText="Close"
          onBackClick={closeModal}
          icon={
            <Icon
              icon={IconType.Warning}
              className="import-spreadsheet__error"
            />
          }
        />
      ),
      [ContentType.VALIDATION_ERROR]: (
        <Modal
          open={true}
          title="Import Spreadsheet"
          text="Required information is missing or invalid"
          confirmButtonText="Try again"
          onConfirmClick={onTryAgainClick}
          backButtonText="Close"
          onBackClick={closeModal}
          icon={
            <Icon
              icon={IconType.Warning}
              className="import-spreadsheet__error"
            />
          }
          content={
            <div className="import-spreadsheet__error-message">
              {displayValidationErrors()}
            </div>
          }
        />
      ),
    };
  }, [displayValidationErrors, fileUploader, onUploadClick]);

  return contentMap[contentType];
};

export default ImportSpreadsheet;
