import { ReactElement, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { store } from 'src/features/store/store';
import { useCheckError } from 'src/features/hooks';
import { setSignatory } from 'src/features/signatories/slices/signatoriesSlice';
import { useGetSignatoriesQuery } from 'src/features/signatories/api/signatoriesApi';
import TemplatesList from 'src/features/templates/components/TemplatesList/TemplatesList';

import './Signatories.scss';

const Signatories = () => {
  const navigate = useNavigate();
  const { data: signatories, isError } = useGetSignatoriesQuery();
  useCheckError(isError, 'Error loading Signatories');

  const onOpenClick = useCallback(
    (id: string) => {
      const signatory = signatories?.data.find((elem) => elem.uid === id);
      store.dispatch(setSignatory(signatory ?? null));
      navigate(paths.viewSignatory(id));
    },
    [navigate, signatories]
  );

  const onEditClick = useCallback(
    (id: string) => {
      const signatory = signatories?.data.find((elem) => elem.uid === id);
      store.dispatch(setSignatory(signatory ?? null));
      navigate(paths.editSignatory(id));
    },
    [navigate, signatories]
  );

  const actions = useMemo<(id: string, name?: string) => ReactElement[]>(
    () => (id) =>
      [
        <Button
          key={0}
          className="template-preview__button"
          onClick={() => onOpenClick(id)}
        >
          Open
        </Button>,
        <Button
          key={1}
          className="template-preview__button_info"
          onClick={() => onEditClick(id)}
        >
          Change
        </Button>,
        // <IconButton key={2} className="primary-button_info">
        //   <Icon icon={IconType.Cross} />
        // </IconButton>,
      ],
    [onEditClick, onOpenClick]
  );

  return (
    <div className="settings__signatories">
      <h2 className="settings-signatories__header">Signatories</h2>
      <TemplatesList
        data={signatories?.data ?? []}
        actionButtons={actions}
        className="settings-signatories__list"
      />
      <Button
        className="settings__add-button"
        color="info"
        startIcon={<Icon icon={IconType.Plus} />}
        component={NavLink}
        to={paths.createSignatory}
      >
        Add a new signatory
      </Button>
    </div>
  );
};

export default Signatories;
