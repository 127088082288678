import MaterialReactTable, {
  MaterialReactTableProps,
  MRT_ColumnDef,
} from 'material-react-table';

import './Table.scss';

type TableProps<T> = {
  columns: MRT_ColumnDef[];
  data: T[];
  enableRowActions?: boolean;
  rowActions?: JSX.Element[];
  enableSorting?: boolean;
  id?: string;
  maxHeight?: string;
};

const Table = <T extends object>({
  columns,
  data,
  enableSorting = false,
  id,
  maxHeight,
  ...rest
}: TableProps<T> & MaterialReactTableProps) => {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enableColumnActions={false}
      enableColumnFilters={false}
      enableSorting={enableSorting}
      enableTopToolbar={false}
      muiTableProps={{
        sx: {
          tableLayout: 'fixed',
        },
      }}
      muiTableContainerProps={{
        sx: { maxHeight: maxHeight ?? 'none' },
      }}
      muiTablePaperProps={{
        id: id,
        elevation: 0,
        sx: {
          borderRadius: '0',
          border: 'none',
        },
      }}
      muiTableHeadCellProps={{
        sx: {
          padding: '6px',
          paddingBottom: '24px',
          paddingTop: '24px',
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          padding: '8px 6px',
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      }}
      {...rest}
    />
  );
};

export default Table;
