import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ErrorResponse } from 'src/shared/models';
import Modal from 'src/shared/components/Modal/Modal';
import { ReactComponent as PlusIcon } from 'src/shared/components/Icon/icons/plus.svg';

import { State, store } from 'src/features/store/store';
import { AUTH } from 'src/features/auth/slices/authSlice';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { setRequestId } from 'src/features/cocRequests/slices/cocRequestsSlice';
import { useActivateAccountMutation } from 'src/features/companies/api/companiesApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './ActivateAccount.scss';

const ResetPassword = () => {
  const navigate = useNavigate();
  const user = useSelector((s: State) => s[AUTH].userProfile);
  const [activateAccount, result] = useActivateAccountMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      toast.error(`Error: ${(result.error as ErrorResponse).data.message}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result.error, result.isError]);

  useEffect(() => {
    if (result.isSuccess) {
      closeModal();
      toast.success('Account activated successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result.isSuccess]);

  return (
    <Modal
      open
      title={`Activate account for "${user?.email}"?`}
      text="Users with an active account will be able to log in the app."
      confirmButtonText="Activate"
      onBackClick={() => store.dispatch(changeModal(null))}
      onConfirmClick={() => user?.userUid && activateAccount(user.userUid)}
      icon={<PlusIcon />}
    />
  );
};

export default ResetPassword;
