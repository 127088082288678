import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { FormField } from 'src/shared/models';
import Select from 'src/shared/components/Select/Select';

import { store } from 'src/features/store/store';
import { Template } from 'src/features/templates/models';
import { setTemplate } from 'src/features/templates/slices/templatesSlice';

import './ChooseTemplate.scss';

type ChooseTemplateProps = {
  templates: Template[];
  onSelect?: () => void;
};

const ChooseTemplate = ({ templates }: ChooseTemplateProps) => {
  const { values } = useFormikContext<FormField>();

  useEffect(() => {
    const id = values.templateUid;
    const template = templates.find((template) => template.uid === id);
    store.dispatch(setTemplate(template ?? null));
  }, [values.templateUid, templates]);

  const getTemplatesOptions = () => {
    return templates.map((template) => {
      return {
        value: template.uid,
        label: `${template.designTemplateUid ? '*' : ''}${template.name}`,
      };
    });
  };

  return (
    <>
      {templates.length > 1 && (
        <div className="choose-template__select">
          <Select
            name="templateUid"
            label="Credential Template"
            options={getTemplatesOptions()}
            placeholder="Select Credential Template"
            required
          />
          <p className="choose-template__select-label">
            ‘*’ = design available
          </p>
        </div>
      )}
    </>
  );
};

export default ChooseTemplate;
