import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { NavLink } from 'react-router-dom';
import { Button, IconButton, Popover } from '@mui/material';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';

import { paths } from 'src/app/routes';
import Card from 'src/shared/components/Card/Card';
import Page from 'src/layouts/BaseLayout/components/Page/Page';

import { ErrorResponse } from 'src/shared/models';
import Icon from 'src/shared/components/Icon/Icon';
import {
  DATE_WITHOUT_TIMEZONE,
  downloadDocument,
  openDocument,
} from 'src/shared/utils';
import Table from 'src/shared/components/Table/Table';
import Dropdown, {
  DropdownElement,
} from 'src/shared/components/Dropdown/Dropdown';
import { IconType } from 'src/shared/components/Icon/IconType';
import SearchBar from 'src/shared/components/SearchBar/SearchBar';

import {
  Credential,
  CredentialStatus as CredentialStatusValues,
} from 'src/features/credentials/models';
import { useCheckAuthorization, useCheckError } from 'src/features/hooks';
import {
  useBatchSendCredentialsMutation,
  useGetCredentialsQuery,
  useLazyNotifyCredentialQuery,
  useRevokeCredentialMutation,
} from 'src/features/credentials/api/credentialsApi';
import CredentialStatus from 'src/features/credentials/components/CredentialStatus/CredentialStatus';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './Credentials.scss';

const SIZE = 10;

const Credentials = (): JSX.Element => {
  const [search, setSearch] = useState<string>('');
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: SIZE,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);

  const [rowSelection, setRowSelection] = useState({});

  useCheckAuthorization();

  const {
    data: credentials,
    isError: isCredentialsError,
    isLoading: isCredentialsLoading,
    isFetching: isCredentialsFetching,
  } = useGetCredentialsQuery({
    searchString: search,
    page: pagination.pageIndex + 1,
    size: pagination.pageSize,
    sorting: sorting,
  });
  useCheckError(isCredentialsError, 'Error loading Credentials');

  const [revoke] = useRevokeCredentialMutation();
  const [notify, notifyResult] = useLazyNotifyCredentialQuery();
  const [sendBatch, sendResult] = useBatchSendCredentialsMutation();

  useEffect(() => {
    setIsLoading(isCredentialsLoading);
    setIsRefetching(isCredentialsFetching);
  }, [isCredentialsFetching, isCredentialsLoading]);

  useEffect(() => {
    if (notifyResult.isError) {
      toast.error(
        `Error: ${(notifyResult.error as ErrorResponse).data.error}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
    if (sendResult.isError) {
      toast.error(
        `Error: ${(sendResult.error as ErrorResponse).data?.error ?? ''}`,
        {
          containerId: CONTAINER_ID_ACTION,
        }
      );
    }
    if (notifyResult.isSuccess || sendResult.isSuccess) {
      toast.success('Successfully sent', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [
    notifyResult.error,
    notifyResult.isError,
    notifyResult.isSuccess,
    sendResult.error,
    sendResult.isError,
    sendResult.isSuccess,
  ]);

  const actions = useCallback(
    (cred: Credential) => {
      const elements: DropdownElement<Credential>[] = [
        {
          text: 'View PDF',
          icon: <Icon icon={IconType.Document} />,
          onClick: (credential: Credential) =>
            credential.uid && openDocument(credential.uid),
        },
        {
          text: 'Download PDF',
          icon: <Icon icon={IconType.Download} />,
          onClick: (credential: Credential) =>
            credential.uid && downloadDocument(credential.uid),
        },
        {
          text: 'Send by email',
          icon: <Icon icon={IconType.Mail} />,
          onClick: (credential: Credential) =>
            credential.uid && notify({ id: credential.uid }),
        },
      ];

      if (cred.status !== CredentialStatusValues.REVOKED) {
        elements.push({
          text: 'Revoke',
          icon: <Icon icon={IconType.CrossCircle} />,
          className: 'credentials__action-button_revoke',
          onClick: (credential: Credential) =>
            credential.uid && revoke(credential.uid),
        });
      }

      return elements;
    },
    [revoke, notify]
  );

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        header: 'Issuance date',
        accessorKey: 'issuedOn',
        Cell: ({ cell }) =>
          dayjs(cell.getValue<string>()).format(DATE_WITHOUT_TIMEZONE),
        size: 110,
      },
      {
        header: 'First name',
        accessorKey: 'recipientFirstName',
      },
      {
        header: 'Last name',
        accessorKey: 'recipientLastName',
      },
      {
        header: 'Email',
        accessorKey: 'recipientEmail',
      },
      {
        header: 'Identifier',
        accessorKey: 'identifier',
        size: 120,
      },
      {
        header: 'Certificate type',
        accessorKey: 'templateName',
        size: 130,
      },
      {
        header: 'Email clicked',
        accessorKey: 'downloaded',
        size: 120,
      },
      {
        header: 'Downloaded on Wallet',
        accessorKey: 'downloadedMobile',
        size: 120,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        size: 130,
        Cell: ({ cell }) => (
          <CredentialStatus value={cell.getValue<CredentialStatusValues>()} />
        ),
      },
      {
        header: '',
        id: 'actions',
        size: 50,
        maxSize: 50,
        Cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
            null
          );
          const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
          };
          const dropdownIsOpen = Boolean(anchorEl);

          const handleClose = () => {
            setAnchorEl(null);
          };

          return (
            <>
              <IconButton onClick={handleClick} className="button_row-actions">
                <Icon icon={IconType.Dots} />
              </IconButton>
              <Popover
                open={dropdownIsOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Dropdown<Credential>
                  elements={actions(row.original as Credential)}
                  element={row.original as Credential}
                  callbackOnClick={handleClose}
                />
              </Popover>
            </>
          );
        },
      },
    ],
    [actions]
  );

  return (
    <Page>
      <div className="credentials">
        <div className="credentials__header">
          <h1>Credentials</h1>

          <div className="credentials-header__button-container">
            {Object.keys(rowSelection).length ? (
              <>
                <Button
                  variant="text"
                  startIcon={<Icon icon={IconType.DownloadBatch} />}
                  color="inherit"
                  onClick={() => downloadDocument(Object.keys(rowSelection))}
                >
                  Batch download
                </Button>
                <Button
                  variant="text"
                  startIcon={
                    <Icon
                      icon={IconType.Send}
                      className="credentials-header__button-icon"
                    />
                  }
                  color="inherit"
                  onClick={() => sendBatch(Object.keys(rowSelection))}
                >
                  Batch send
                </Button>
              </>
            ) : null}
            <Button
              variant="contained"
              startIcon={<Icon icon={IconType.Plus} />}
              component={NavLink}
              to={paths.createCredential}
            >
              New credential
            </Button>
          </div>
        </div>
        {/* <Tabs value={currentTab} values={TABS} onChange={handleTabClick} /> */}
        <SearchBar onChange={(e) => setSearch(e.target.value)} />

        <Card className="credentials-card">
          <Table<Credential>
            columns={columns}
            data={credentials?.data ?? []}
            enableSorting
            manualPagination
            manualSorting
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={credentials?.count ?? 0}
            enableRowSelection
            onRowSelectionChange={setRowSelection}
            getRowId={(originalRow) => (originalRow as Credential).uid}
            state={{
              isLoading,
              pagination,
              sorting,
              showProgressBars: isRefetching,
              rowSelection,
            }}
          />
        </Card>
      </div>
    </Page>
  );
};

export default Credentials;
