import { Chip } from '@mui/material';

import { CoCStatus as CoCStatusValues } from 'src/features/responsiblePersons/models';

type CoCStatusProps = {
  value: CoCStatusValues;
};

const STATUSES = {
  [CoCStatusValues.ACTIVE]: {
    label: 'Active',
    className: 'coc-status_active',
  },
  [CoCStatusValues.NOT_REGISTERED]: {
    label: 'Not registered',
    className: 'coc-status_not-registered',
  },
};

const CoCStatus = ({ value }: CoCStatusProps) => {
  return STATUSES[value] ? (
    <Chip label={STATUSES[value].label} className={STATUSES[value].className} />
  ) : (
    <></>
  );
};

export default CoCStatus;
