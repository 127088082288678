import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponsiblePerson } from '../models';

export const RESPONSIBLE_PERSONS = 'RESPONSIBLE_PERSONS';

interface ResponsiblePersonsState {
  responsiblePerson: ResponsiblePerson | null;
}

const initialState: ResponsiblePersonsState = {
  responsiblePerson: null,
};

const responsiblePersonsSlice = createSlice({
  name: RESPONSIBLE_PERSONS,
  initialState,
  reducers: {
    setResponsiblePerson: (
      state: ResponsiblePersonsState,
      action: PayloadAction<ResponsiblePerson | null>
    ) => {
      state.responsiblePerson = action.payload;
    },
  },
});

export const { setResponsiblePerson } = responsiblePersonsSlice.actions;

export default responsiblePersonsSlice.reducer;
