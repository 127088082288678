import { Chip } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

import { CredentialStatus as CredentialStatusValues } from 'src/features/credentials/models';

type CoCStatusProps = {
  value: CredentialStatusValues;
};

const STATUSES = {
  [CredentialStatusValues.ACTIVE]: {
    icon: <Icon icon={IconType.CheckCircle} />,
    label: 'Active',
    className: 'coc-status_active',
  },
  [CredentialStatusValues.REVOKED]: {
    icon: <Icon icon={IconType.CrossCircle} />,
    label: 'Revoked',
    className: 'coc-status_not-registered',
  },
};

const CredentialStatus = ({ value }: CoCStatusProps) => {
  return (
    <Chip
      icon={STATUSES[value].icon}
      label={STATUSES[value].label}
      className={STATUSES[value].className}
    />
  );
};

export default CredentialStatus;
