import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { paths } from 'src/app/routes';
import { ErrorResponse } from 'src/shared/models';
import Modal from 'src/shared/components/Modal/Modal';

import { State, store } from 'src/features/store/store';
import { changeModal } from 'src/features/modal/slices/modalSlice';
import { GUESTApplicationStatus } from 'src/features/cocRequests/models';
import {
  COC_REQUEST,
  setRequestId,
} from 'src/features/cocRequests/slices/cocRequestsSlice';
import { useChangeGUESTApplicationStatusMutation } from 'src/features/cocRequests/api/cocRequestsApi';
import { CONTAINER_ID_ACTION } from 'src/features/notifications/components/NotificationContainer/NotificationContainer';

import './RejectCoC.scss';

const RejectCoC = () => {
  const requestId = useSelector((s: State) => s[COC_REQUEST].requestId);

  const navigate = useNavigate();
  const [rejectRequest, result] = useChangeGUESTApplicationStatusMutation();

  const closeModal = () => {
    store.dispatch(changeModal(null));
    store.dispatch(setRequestId(null));
  };

  useEffect(() => {
    if (result.isError) {
      closeModal();
      toast.error(`Error: ${(result.error as ErrorResponse).data.error}`, {
        containerId: CONTAINER_ID_ACTION,
      });
    }
    if (result.isSuccess) {
      closeModal();
      navigate(paths.cocRequests);
      toast.success('GUEST Rejected Successfully', {
        containerId: CONTAINER_ID_ACTION,
      });
    }
  }, [navigate, result]);

  return (
    <Modal
      open={true}
      title="Reject the CoC Application"
      text="Are you sure you want to Reject this CoC Application?"
      confirmButtonText="Reject Application"
      onBackClick={() => store.dispatch(changeModal(null))}
      onConfirmClick={() =>
        requestId &&
        rejectRequest({
          uid: requestId,
          status: GUESTApplicationStatus.REJECTED,
        })
      }
      color="error"
    />
  );
};

export default RejectCoC;
