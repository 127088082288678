import { Chip } from '@mui/material';

import Icon from 'src/shared/components/Icon/Icon';
import { IconType } from 'src/shared/components/Icon/IconType';

type AccountStatusProps = {
  verified: boolean;
};

const AccountStatus = ({ verified }: AccountStatusProps) => {
  return verified ? (
    <Chip
      label="Confirmed"
      icon={<Icon icon={IconType.CheckCircle} />}
      className="account-status_confirmed"
    />
  ) : (
    <Chip
      label="Not Confirmed"
      icon={<Icon icon={IconType.CrossCircle} />}
      className="account-status_not-confirmed"
    />
  );
};

export default AccountStatus;
